import { message } from 'antd'

// 全局提示
export function Msg(type,text){
    message.destroy()
    if (type === 'warning'){
        message.warning(text)
    } else if (type === 'error') {
        message.error(text)
    } else if (type === 'success'){
        message.success(text)
    } else if ( type === 'info'){
        message.info(text)
    }
}

