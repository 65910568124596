import React,{ useEffect, useState } from 'react'
import FooterShare from './FooterShare'
import 'styles/h5share/share.scss'
import { Skeleton } from 'antd'
import axios from '../../libs/axios'
import className from 'classname'
import openApp from './openApp'
import baseUrl from './baseUrl'
const  FlashShare = props => {
    const { id } = props.match.params // 快讯的详情的ID
    const [ data,setData ] = useState({})
    const [ isLoading,setisLoading ] = useState(true)

    useEffect(()=>{
        // 请求视频的详情
        // console.log('id', id)
        axios.post(`${baseUrl()}movie/detail`, {id}).then(res => {
            // console.log('res', res)
            if (res.code === 10000){
                setData(res.data)
                setisLoading(false)
            }
        })
    },[id])
    const goVip = () => {
        openApp()
    }
    return (
        <section className='share-container'>
            <Skeleton active loading={isLoading}>
                <div className='share-cont'>
                    <div className='videocon'>
                        {
                            (data.address && data.address.includes('https://image.deeptechchina.com') || data.address && data.address.includes('http://appvid.mittrchina.com')) ?  <video src={data.address}  className="video-con" controls poster={data.img}></video> :
                                data.address && data.address.includes('https://v.qq.com') ? <iframe src={data.address}  className="video-con" title={data.id}></iframe> :
                                    <div className="video-con" dangerouslySetInnerHTML={{ __html:`<html>
                        <head>
                            <title></title>
                            <style type="text/css">
                            </style>
                        </head>
                        <body>
                            <div>
                            <embed src=${data.address} autostart=false></embed>
                            </div>
                        </body>
                        </html>` }} ></div>
                        }
                        {/* <video controls preload="auto" 
                            disablePictureInPicture
                            // autoPlay 
                            controlslist="nodownload noremoteplayback"
                            className='video-con'
                            poster={data.img}
                            src={data.address}
                        >
                        </video> */}
                        {/* <source src={data.address} type="video/mp4" />
                当前浏览器不支持视频播放，请升级浏览器或者切换到谷歌浏览器访问！ */}
                        {data.is_vip === 1 && <div className='mengceng'></div>} 
                        {data.is_vip === 1 && <div className='vipbtn' onClick={goVip}> <img src={require('assets/images/appimg/vip.png')} alt="logo"/>成为付费会员，浏览更多内容</div>}
                    </div>
                    <div className='text-container'>
                        <h2 className='title videotitle'>{data.title}</h2>
                        <h5 className='sub-title'>分享自【麻省理工科技评论】APP</h5>
                        {/* <article dangerouslySetInnerHTML={{__html:data.content}}></article> */}
                        {/* <p className='title2'>{data.title}</p> */}
                        <div className={className(['contents', {'vip': data.is_vip === 1}])}>
                            <article dangerouslySetInnerHTML={{__html:data.content}}  className={className([{'vipcont': data.is_vip === 1}])}></article>
                            {/* {data.is_vip === 1 && <div className='vipbtn' onClick={goVip}>成为付费会员，浏览更多内容</div>} */}
                        </div>
                        {/* <p>丙二酰辅酶A是一种重要的微生物胞内代谢中间产物,由于其独特的结构,可衍生为几类具有独特结构的化合物,包括:脂肪酸类化合物、生物基化学品和植物源黄酮及聚酮类天然产物等.这些化合物广泛应用于食品、医药、化工和能源等领域。</p>
                <p>目前,微生物大量合成上述丙二酰辅酶A衍生物的限制性因素是其胞内较低的丙二酰辅酶A含量.本文中,笔者以提高微生物合成丙二酰辅酶A衍生物为核心,综述了提高其前体丙二酰辅酶A导向目标产物的代谢工程策略,包括丙二酰辅酶A合成途径的强化、竞争支路途径的消减以及其含量的精细调控等,以期为微生物合成丙二酰辅酶A衍生物的进一步研究提供参考。</p> */}
                    </div>
                </div>
                <FooterShare />
            </Skeleton>
            
        </section>
    )
}
export default FlashShare