
import React, { Component } from 'react'
import {  Modal,  Upload, message, Form } from 'antd'
import { getStorage } from 'utils/storage'
import Avatar from 'comps/Avatar'
import 'styles/user/user.scss'
import reqwest from 'reqwest'
import { connect } from 'react-redux'
import { getusers } from '@redux/user/action'
interface stateType{
    imageUrl: string,
    fileList: any,
}
interface propsType{
    isVisible: boolean,
    clickCancel: Function,
    getusers: Function,
}
@connect(state => state, { getusers })
class NickName extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            imageUrl: '',
            fileList: [],
        }
    }

    componentDidMount(){

    }

    //取消
    handleCancle = () =>{
        this.props.clickCancel()
        this.setState({
            imageUrl: ''
        })
    }

    getBase64 = (img, callback) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => callback(reader.result))
        reader.readAsDataURL(img)
    }

    handleUpload = () => {
        const { fileList } = this.state
        const formData = new FormData()
        fileList.forEach(file => {
            // console.log('file', file)
            formData.append('file', file)
        })

        // let baseURL = process.env.NODE_ENV === 'development' ? 'http://testapi.mittrchina.com/' : 'http://testapi.mittrchina.com/'
        let baseURL = process.env.NODE_ENV === 'development' ? 'http://testapi.mittrchina.com/' : 'https://apii.mittrchina.com'
        // You can use any AJAX library you like
        // console.log('formData', formData)
        reqwest({
            url: baseURL + '/bindAvatar',
            method: 'post',
            processData: false,
            data: formData,
            headers: { token: getStorage('token') },
            success: () => {
                this.handleCancle()
                this.setState({
                    fileList: [],
                }, () => {
                    this.props.getusers()
                    // console.log('this.state.fileList',this.state.fileList)
                })
                // message.success('upload successfully.')
            },
            error: () => {
                // message.error('upload failed.')
            },
        })
    }


    render() {
        const {isVisible} = this.props
        let {imageUrl} = this.state
        const props = {
            onRemove: file => {
                // this.setState(state => {
                //     const index = state.fileList.indexOf(file)
                //     const newFileList = state.fileList.slice()
                //     newFileList.splice(index, 1)
                //     return {
                //         fileList: newFileList,
                //     }
                // })
            },
            beforeUpload: file => {
                // console.log('file', file)
                this.setState(state => ({
                    fileList: [...state.fileList, file]
                }), () => {
                    // console.log('fileList', this.state.fileList)
                })
                return false
            },
            onChange:info => {
                // console.log('info',info)
                let file = info.fileList
                if (file.length > 1){
                    file = file.slice(-1)
                }
                file = file[0]
                // console.log('file', file)
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
                const isLt2M = file.size / 1024 / 1024 < 5
                if (!isJpgOrPng) {
                    message.error('可上传的图片格式：jpg、jpeg、png')
                } else if (!isLt2M) {
                    message.error('图片大小不超过5M')
                } else {
                    this.getBase64(file.originFileObj, imageUrl => this.setState({
                        imageUrl,
                    }, () => {
                        // console.log('this.state.imageUrl',this.state.imageUrl)
                    }))
                }


            },
            showUploadList:false,
            // transformFile: info => {
            //     console.log('transformFile', info)
            // },
        }

        return (
            <Modal
                className="user-avatar-box"
                title="头像设置"
                // footer={null}
                okText="保存头像"
                visible={isVisible}
                // visible={true}
                width={566}
                onCancel={this.handleCancle}
                onOk={this.handleUpload}
                destroyOnClose
            >
                <div className="content">
                    <div className="avatar-border">
                        {
                            imageUrl ? <img src={imageUrl} alt=""  className="userset"/> : <img src={require('assets/images/user/user-set.png')} alt=""/>
                        }
                    </div>
                    <div className="update">
                        <div>说明:</div>
                        <div>可上传的图片格式：</div>
                        <div>jpg、jpeg、png</div>
                        <div>图片大小不超过5M</div>
                        <Upload {...props} >
                            <div className="checkImg">选择图片</div>
                        </Upload>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default NickName

