
import React, { Component } from 'react'
import fromatTime from 'utils/formatTime'
import { Skeleton } from 'antd'
interface stateType{
    load: boolean
}
interface propsType{
    item: any
}
class FourItem extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            load: false
        }

    }
    
    componentDidMount(){
    }

    loadSuccess = () => {
        this.setState({
            load: true
        })
    }
    render() {
        const {item} = this.props
        const {load} = this.state
        return (
            <div className="typeitem">
                <div className="little-title">{item.typeName}</div>
                <a href={`/news/detail/${item.id}`}><div className="title-con" title={item.name}>{item.name}</div></a>
                {/* <a href={`/news/detail/${item.id}`}><Avatar photoUrl={item.cover} photoWidth={'295px'} photoHeight={'178px'} isRadius={false}></Avatar></a> */}
                <a href={`/news/detail/${item.id}`} className="img-box">
                    <img src={item.cover+ '?imageView2/2/w/295/h/178'} alt="" onLoad={this.loadSuccess} className="realimg"/>
                    {
                        !load ? <Skeleton active className="loadingImg"/> : null
                    }
                </a>
            </div>
        )
    }
}
export default FourItem

