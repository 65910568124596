import React from 'react'
import 'styles/h5share/agree.scss'

const UserAgreement = () => {
    return <section className='h5agree useragreement'>
        <div className='preface'>本用户协议最近更新时间为2021年6月24日。</div><br/>
        <div className="title">一、总则<br/></div>
        1.1  麻省理工科技评论APP的所有权和运营权归北京演绎科技有限公司所有。北京演绎科技有限公司为MIT Technology Review中国唯一版权合作方。 <br/>
        1.2 您在注册之前，应当仔细阅读本协议，并同意遵守本协议后方可成为注册用户。一旦注册成功，则您与本APP之间自动形成协议关系，您应当受本协议的约束。您在使用特殊的服务或产品时，应当同意接受相关协议后方能使用。 <br/>
        1.3 本协议可由本APP随时更新，您应当及时关注并同意本站不承担通知义务。本APP的通知、公告、声明或其它类似内容是本协议的一部分。<br/>
        <div className="title">二、服务内容与付费<br/></div>
        2.1 本APP的具体内容由北京演绎科技有限公司根据实际情况提供。 <br/>
        2.2 本APP仅提供相关的内容资讯，除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)均应由您自行负担。<br/>
        2.3 您在本APP上使用第三方提供的产品或服务时，除遵守本协议约定外，还应遵守第三方的用户协议。本APP和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。<br/>
        2.4 本APP有权适时更改订阅内容、数量及服务种类来满足不同用户或不同时间的需求。<br/>
        2.5 您对本人所支付的任何费用负有不可推卸的责任。<br/>
        2.6 您需支付的费用数额将会清楚地显示在您的订购页面或订购过程中。当您选择订购时，本APP将默认您同意以您订购时被告知的费用金额订购。若您拥有任何享受折扣的资格，价格将会在您确认订购时被录入，并在整个订购期内不会被改变。<br/>
        本APP可能根据实际需要对收费服务的收费标准、方式进行修改和变更，本APP也可能会对部分免费服务开始收费。前述修改、变更或开始收费前，本APP将在相应服务页面进行通知或公告。自通知或公告之日起，您对本APP及相关服务的继续使用将被视为对所有修改的接受。 <br/>
        　　本APP上各项收费产品（实物产品、虚拟产品等），一经售出，不予退换。<br/>
        　　 对于定价，本APP保留可以随时更改各项收费产品的价格的权利，且有权更正可能无意发生的错误定价。出现定价错误时，本APP将向您发出通知，并允许您以正确的价格继续交易，或在您不承担费用的情况下取消该笔订单。<br/>
        2.7 订购选项：<br/>
         - CN ¥128. 00 / 三个月；¥388. 00 / 一年<br/>
         - 付款将在您确认购买时向您选择的支付账户（iTunes、支付宝、微信）收取。<br/>
        2.8 续订：苹果手机（Apple iPhone）用户，除非在任何订单或促销活动中有特别提示，普通订阅时，若您提交订单，系统将默认您同意在您最初的订阅期满后（或在其后的每次续订期满后）自动续订相同期段并支付相应费用。<br/>
        苹果AppStore订阅细则：<br/>
        - 您在有效的订阅期内不允许取消当前订阅，但可以取消自动续订。<br/>
        - 除非您在订阅邮箱期结束前至少24小时关闭自动续订，否则您的订阅到期后将会自动续订。<br/>
        - 您的账号将会在订阅有效期到期前24小时内以购买时的价格为您自动续订。<br/>
        - 您可以在您的iTunes账户设置中管理您的订阅和自动续订。<br/>
        - 您可以通过您的iTunes您的订阅设置在免费试用期内取消订阅。这必须在订阅期结束前24小时完成，以免被收取费用。<br/>
        - 有关自动续订和如何取消的详细信息，请访问<a href="http://support.apple.com/kb/ht4098">http://support.apple.com/kb/ht4098</a> 。<br/>
        <div className="title">三、用户账号<br/></div>
        3.1 经本APP注册系统完成注册程序并通过身份认证的用户即成为正式用户，可以获得本APP规定用户所应享有的一切权限；未经认证仅享有本APP规定的部分会员权限。本APP有权对会员的权限设计进行变更。 <br/>
        3.2 用户应按照注册要求使用真实的信息注册。用户有义务保证密码和账号的安全，用户利用该密码和账号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本APP不承担任何责任。如用户发现账号遭到未授权的使用或发生其他任何安全问题，应立即修改账号密码并妥善保管，如有必要，请通知本APP。因黑客行为或用户的保管疏忽导致账号非法使用，本APP不承担任何责任。<br/>
        3.3 除自行注册账号外，用户也可选择通过本APP的账号管理后台，授权使用其合法拥有的包括但不限于新浪微博、微信等第三方软件或平台用户账号注册并登录使用本APP，但第三方软件或平台对此有限制或禁止的除外。当用户以前述已有账号登录使用的，同样适用本条款中的相关约定。<br/>
        <div className="title">四、使用规则<br/></div>
        4.1 用户在使用本APP过程中，必须遵守国家有关法律规定，必须遵循以下原则：：<br/>
         (1)不得为任何非法目的而使用网络服务系统； <br/>
         (2)遵守所有与网络服务有关的网络协议、规定和程序； <br/>
         (3)不得利用本APP进行任何可能对互联网的正常运转造成不利影响的行为； <br/>
         (4)不得利用本APP进行任何不利于本站的行为。<br/>
        4.2 用户承诺将遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》和《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、实施办法。 <br/>
        4.3 用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在本APP任何页面发布、转载、传送含有下列内容之一的信息，否则本APP有权自行处理并不通知用户：<br/>
         (1)违反宪法确定的基本原则的； <br/>
         (2)危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的； <br/>
         (3)损害国家荣誉和利益的； <br/>
         (4)煽动民族仇恨、民族歧视，破坏民族团结的； <br/>
         (5)破坏国家宗教政策，宣扬邪教和封建迷信的； <br/>
         (6)散布谣言，扰乱社会秩序，破坏社会稳定的；<br/>
         (7)散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的； <br/>
         (8)侮辱或者诽谤他人，侵害他人合法权益的； <br/>
         (9)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的； <br/>
         (10)以非法民间组织名义活动的；<br/>
         (11)含有法律、行政法规禁止的其他内容的。<br/>
        4.4 用户承诺对其发表或者上传于本APP的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、音乐、电影、表演和录音录像制品和电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成本APP被第三人索赔的，用户应全额补偿本APP一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用)。<br/>
        4.5 当第三方认为用户发表或者上传于本APP的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本APP发送权利通知书时，用户同意本APP可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，本APP将不会自动恢复上述删除的信息。<br/>
        4.6 如用户在使用网络服务时违反上述任何规定，本APP有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户发表的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。<br/>
        <div className="title">五、隐私政策<br/></div>
        5.1 本APP不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本APP的非公开内容，但下列情况除外：<br/>
         (1)事先获得用户的明确授权； <br/>
         (2)根据有关的法律法规要求；<br/>
         (3)按照相关政府主管部门的要求；<br/>
         (4)为维护社会公众的利益。<br/>
        5.2 本APP可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本APP同等的保护用户隐私的责任，则本APP有权将用户的注册资料等提供给该第三方。<br/>
        5.3 在不透露单个用户隐私资料的前提下，本APP有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。<br/>
        5.4 隐私政策的具体内容详见本APP发布及更新的隐私政策。<br/>

        <div className="title">六、版权声明<br/></div>
        6.1 本APP的文字、图片、音频、视频等版权均归北京演绎科技有限公司享有或与作者共同享有，未经著作权人许可，不得任意转载。 <br/>
        6.2 本APP特有的标识、版面设计、编排方式等版权均属北京演绎科技有限公司享有，未经著作权人许可，不得任意复制或转载。 <br/>
        6.3 使用本APP的任何内容均应注明“来源于麻省理工科技评论APP”及署上作者姓名，按法律规定需要支付稿酬的，应当通知本APP及作者及支付稿酬，并独立承担一切法律责任。<br/>
        6.4 恶意转载本APP内容的，本APP保留将其诉诸法律的权利。<br/>

        <div className="title">七、责任声明<br/></div>
        7.1 用户明确同意其使用本APP网络服务所存在的风险及一切后果将完全由用户本人承担，本APP对此不承担任何责任。 <br/>
        7.2 本APP 无法保证网络服务一定能满足用户的要求，也不保证网络服务的及时性、安全性、准确性。 <br/>
        7.3 本APP不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由本APP实际控制的任何网页上的内容，本APP不承担任何责任。<br/>
        7.4 对于因不可抗力或本APP不能控制的原因造成的网络服务中断或其它缺陷，本APP不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。<br/>
        7.5 本APP有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论其通知与否，本APP对用户和任何第三人均无需承担任何责任。<br/>

        <div className="title">八、附则<br/></div>
        8.1 本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。 如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向北京演绎科技有限公司所在地的人民法院提起诉讼。<br/>
        8.2 如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。<br/>
        8.3 本协议解释权及修订权归北京演绎科技有限公司所有。<br/>
    </section>
}
export default UserAgreement