
import React, { Component } from 'react'
import fromatTime from 'utils/formatTime'
import Avatar from 'comps/Avatar'
import className from 'classname'
import { Skeleton } from 'antd'
interface stateType{
    load: boolean
}
interface propsType{
    ite: any
}
class ListItem extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            load: false
        }

    }
    
    componentDidMount(){
    }

    loadSuccess = () => {
        this.setState({
            load: true
        })
    }
    render() {
        const {ite} = this.props
        const {load} = this.state
        return (
            <>
                {
                    ite.ad && ite.ad.img ?  <img src={ite.ad.img} alt="" className="inforlistimg"/> : null
                }
                {
                    ite.items.map(item => {
                        return <dl className={className(["infor-item", {'item-noborder': ite.ad && ite.ad.img}])} key={item.id}>
                            <dd>
                                <div>
                                    <div className="typename">{item.typeName}</div>
                                    <a href={`/news/detail/${item.id}`}><h1 title={item.name}>{item.name}</h1></a>
                                    <div className="con">{item.summary}</div>
                                </div>
                                <div className="type">
                                    <span>{item.authors[0].username}</span>
                                    <span>{fromatTime(item.start_time * 1000)}</span>
                                </div>
                            </dd>
                            <dt>
                                {/* <a href={`/news/detail/${item.id}`}><Avatar photoUrl={item.cover} photoWidth={'295px'} photoHeight={'178px'} isRadius={false}></Avatar></a> */}
                                <a href={`/news/detail/${item.id}`}><img src={item.cover + '?imageView2/2/w/296/h/178'} alt="" onLoad={this.loadSuccess}/></a>
                                {
                                    !load ? <Skeleton active className="loadingImg"/> : null
                                }
                            </dt>
                        </dl>
                                            
                    })
                }
                                   
            </>
        )
    }
}
export default ListItem

