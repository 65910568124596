
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import 'styles/video/videodetail.scss'
import VideoItem from 'comps/video/VideoItem'
import fromatTime from 'utils/formatTime'
interface stateType{
    id: string,
    detailData: any[],
    recommendData: any[]
}
interface propsType{
    match: any
}
class VideoDetail extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            detailData:[],
            recommendData: []
        }

    }
    
    componentDidMount(){
        this.getVideoDetail()
        this.getRecommendVideo()
    }

    //视频详情
    getVideoDetail = () => {
        this.axios.get('/movie/details',{params:{id:this.state.id}}).then(res => {
            if (res.code === 10000){
                // console.log('res.data', res.data)
                this.setState({
                    detailData: res.data
                })
                document.title = '麻省理工科技评论-' + res.data.title
            }
        })
    }

    //相关视频
    getRecommendVideo = () => {
        this.axios.get('/movie/recommend',{params:{id:this.state.id, limit: 8}}).then(res => {
            if (res.code === 10000){
                this.setState({
                    recommendData: res.data.items
                })
            }
        })
    }
    render() {
        const { detailData, recommendData } = this.state
        return (
            <div className="video-detail">
                <div className="main-video-box">
                    {
                        detailData.address && detailData.address.includes('https://image.deeptechchina.com') ?  <video src={detailData.address}  className="main-video" controls></video> :
                            detailData.address && detailData.address.includes('https://v.qq.com') ? <iframe src={detailData.address}  className="main-video" title={detailData.id}></iframe> :
                                <div className="main-video" dangerouslySetInnerHTML={{ __html:`<html>
                        <head>
                            <title></title>
                            <style type="text/css">
                            </style>
                        </head>
                        <body>
                            <div>
                            <embed src=${detailData.address}></embed>
                            </div>
                        </body>
                        </html>` }} ></div>
                    }
                  
                </div>
                <div className="title-share">
                    <div className="title-box">
                        <div className="title"><div className="cont" title={detailData.title}>{detailData.title}</div> <div className="time">{fromatTime(detailData.start_time * 1000)}</div></div>
                        {/* <div className="share-box">
                            <span>分享到：</span>
                            <img src={require('assets/images/weixin.svg')} alt="" className="share-weixin"/>
                            <img src={require('assets/images/weibo.svg')} alt="" className="share-weibo"/>
                        </div> */}
                    </div>
                   
                </div>
                <div className="videolist">
                    {
                        recommendData.map(item => {
                            return <VideoItem videoData={item}></VideoItem>
                        })
                    }
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        getBannerList: state.getBannerList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(VideoDetail))

