import React, { Component } from 'react'
import { Form, Checkbox, Button } from 'antd'
import 'styles/regist/regist.scss'
import className from 'classname'
interface stateType {
    params:{
        mobile: string,
        pwd: string,
        pwd2: string,
        code: string,
        email: string,
        nick_name: string
    },
    type: number,
    isCheck: boolean,
    codeText: string,
    codeBtnisAble: boolean,
    maxTime: number,
    pwdplaceholder: boolean
}
interface propsType {
}

class Regist extends Component<propsType, stateType> {
    constructor(props) {
        super(props)
        this.state = {
            params:{
                mobile: '',
                pwd: '',
                pwd2: '',
                code: '',
                email: '',
                nick_name: ''
            },
            type:1,
            isCheck: localStorage.getItem('contractChecked') ? JSON.parse(localStorage.getItem('contractChecked')) : false,
            codeText:'获取验证码',
            codeBtnisAble: false,
            maxTime: 60,
            pwdplaceholder: true
        }
    }

    componentWillMount(){
        let params = JSON.parse(localStorage.getItem('params'))
        if (params){
            this.setState({params}, () => {
                // console.log('this.state.params',this.state.params)
            })
        }
    }
    componentDidMount(){
        // let param = localStorage.getItem('params')
        // if (param){
        //     console.log('param', JSON.parse(param))

        //     let params = JSON.parse(param)
        //     this.setState({params}, () => {
        //         console.log('this.state.params',this.state.params)
        //     })
        // }

    }

    //手机号校验
    firstChecked = (rule, value) => {
        const regPhone = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
        if (!value) {
            return Promise.reject('手机号不能为空')
        } else {
            if (!regPhone.test(value)) {
                return Promise.reject('手机号格式不正确 请重新输入')
            }
        }
        return Promise.resolve()
    }

    //邮箱校验
    emailChecked = (rule, value) => {
        // const regEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
        const regEmail = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,4}$/
        if (!value) {
            return Promise.reject('邮箱不能为空')
        } else {
            if (!regEmail.test(value)) {
                return Promise.reject('邮箱格式不正确 请重新输入！')
            }
        }
        return Promise.resolve()
    }

    //昵称校验
    nameChecked = (rule, value) => {
        if (!value) {
            return Promise.reject('用户昵称不能为空')
        } else if (value.length > 20){
            return Promise.reject('用户昵称超出长度限制')
        }
        return Promise.resolve()
    }

    //密码校验
    pwdChecked = (rule, value) => {
        // let reg = /^(?![A-z0-9]+$)(?![A-z~@*()_]+$)(?![0-9~@*()_]+$)([A-z0-9~@*()_]{6,20})$/
        let reg = /(?=.*[a-z])(?=.*\d)(?=.*[#@!~%^&*+=_])[a-z\d#@!~%^&*+=_]{6,20}/
        if (!value){
            return Promise.reject('密码不能为空')
        } else if (!reg.test(value) || value.length < 6 || value.length > 20){
            return Promise.reject('您输入的密码不符合规则,请重新输入')
        }
        return Promise.resolve()
    }
    
    //重置密码校验
    pwd2Checked = (rule, value) => {
        if (!value){
            return Promise.reject('密码不能为空')
        } else if (value !== this.state.params.pwd){
            return Promise.reject('两次输入的密码不一致，请重新输入')
        }
        return Promise.resolve()
    }

    handleFinish = values => {
        console.log('allValues', values)
        let params = values
        this.axios.post('/register', params).then(res => {
            if (res.code === 10000){
                localStorage.removeItem('contractChecked')
                localStorage.removeItem('params')
                window.location.href = '/login'
            }
        })
    }

    handleChange = (changedValues, allValues) => {
        // console.log('changedValues', changedValues)
        let params = Object.assign({}, this.state.params, changedValues)
        this.setState({params}, () => {
            // console.log('this.state.params', this.state.params)
        })
    }

    //获取验证码
    getCode = values => {
        const regPhone = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
        let mobile = this.state.params.mobile
        if (mobile && regPhone.test(mobile)) {
            const params = Object.assign({},{mobile: mobile}, {type: this.state.type})
            this.axios.post('/code', params).then(res => {
                console.log(res)
                this.setState({ codeBtnisAble:true })
                let siv = setInterval(() => {
                    this.setState( preState => ({
                        maxTime: preState.maxTime - 1,
                        codeText: `${preState.maxTime - 1}s`
                    }), () => {
                        if (this.state.maxTime === 0) {
                            clearInterval(siv)
                            this.setState({
                                codeBtnisAble: false, codeText: "重新获取验证码", maxTime: 60
                            })
                        }
                    })
                }, 1000)
            })
        }
        
    }

    //输入密码
    pwdInput = () => {
        this.setState({
            pwdplaceholder: false
        })
    }

    //阅读用户协议
    changeBox = e => {
        localStorage.setItem('contractChecked',  e.target.checked)
        this.setState({
            isCheck: e.target.checked
        })
        
    }

    //查看用户协议
    toContract = () => {
        localStorage.setItem('params',JSON.stringify(this.state.params))
        window.location.href = '/contract' 
    }
    render() {
        const {codeText, codeBtnisAble, pwdplaceholder, params, isCheck} = this.state
        return (
            <div className="regist-box">
                <div className="title">注册</div>
                <div className="content">
                    <Form name='regist' onFinish={this.handleFinish} onValuesChange={this.handleChange}>
                        <Form.Item
                            className='item'
                            name='mobile'
                            rules={[{ validator: this.firstChecked }]}
                            validateTrigger='onChange'
                            initialValue={params.mobile}
                        >
                            <input type="text" placeholder="请输入手机号" />
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='email'
                            rules={[{ validator: this.emailChecked }]}
                            validateTrigger='onChange'
                            initialValue={params.email}
                        >
                            <input type="text" placeholder="请输入电子邮箱" />
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='nick_name'
                            rules={[{ validator: this.nameChecked }]}
                            validateTrigger='onChange'
                            initialValue={params.nick_name}
                        >
                            <input type="text" placeholder="请输入用户昵称" />
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='code'
                            rules={[{required:true, message:'短信验证码不能为空'}]}
                            validateTrigger='onChange'
                            initialValue={params.code}
                        >
                            <div>
                                <input type="text" placeholder="请输入验证码"  defaultValue={params.code}/>
                                <Button className='vercode' onClick={this.getCode} disabled={codeBtnisAble}>{codeText}</Button>
                            </div>
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='pwd'
                            rules={[{validator: this.pwdChecked}]}
                            validateTrigger='onChange'
                            initialValue={params.pwd}
                        >
                            <div className="pwdcon">
                                <input type="password" placeholder="请输入密码" onInput={this.pwdInput} defaultValue={params.pwd}/>
                                <span className={className([{'pwdplace' : !pwdplaceholder && params.pwd}])}>6-20位字母、数字、符号组合</span>
                            </div>
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='pwd2'
                            rules={[{ validator: this.pwd2Checked }]}
                            validateTrigger='onChange'
                            initialValue={params.pwd2}
                        >
                            <input type="password" placeholder="请再次输入密码" />
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name="checked"
                            valuePropName="checked"
                            initialValue={isCheck ? 'checked' : null}
                            rules={[
                                {
                                    validator: (_, value) => value ? Promise.resolve() : Promise.reject('阅读并勾选同意用户协议以完成注册'),
                                },
                            ]}
                        >
                            <Checkbox className='txt' onChange={this.changeBox}>已阅读并同意 <span onClick={this.toContract} className="contract">《用户注册协议》</span></Checkbox>
                        </Form.Item>
                        <div className="foot">
                            <button type="submit" className="btnregist" ><span>完成注册</span><img src={require('assets/images/arrows.svg')} alt="" className="arrows"/></button>
                            <div className='login'>
                                <span></span>
                                <span>已有账号点击<a href='/login'>登录</a></span>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

export default Regist