export default function openApp() {
    if (!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
        // var ifr = document.createElement("iframe")
        // ifr.src = "taobao://" /***打开app的协议，有ios同事提供***/
        // ifr.style.display = "none" 
        // document.body.appendChild(ifr)
        // window.location.href = 'taobao://'
        // window.setTimeout(function(){
        //     document.body.removeChild(ifr)
        //     window.location.href = "https://apps.apple.com/cn/app/%E9%BA%BB%E7%9C%81%E7%90%86%E5%B7%A5%E7%A7%91%E6%8A%80%E8%AF%84%E8%AE%BA/id1252537470" /***下载app的地址***/
        // },2000)
        window.location.href = "https://apps.apple.com/cn/app/%E9%BA%BB%E7%9C%81%E7%90%86%E5%B7%A5%E7%A7%91%E6%8A%80%E8%AF%84%E8%AE%BA/id1252537470" /***下载app的地址***/
    } else if (navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1) {
        // window.location.href = "openwjtr://com.mittrchina.mit" /***打开app的协议，有安卓同事提供***/
        // window.setTimeout(function(){
        //     window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.mittrchina.mit" /***打开app的协议，有安卓同事提供***/
        // },2000)
        window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.mittrchina.mit" /***打开app的协议，有安卓同事提供***/
    }
}



