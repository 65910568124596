import React from 'react'
import openApp from './openApp'
const FooterShare = props => {
    // 打开APP按钮 
    const openAppCheck = () =>{
        // 需要更换应用市场的链接地址
        // window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.deeptechchina.app'
        openApp()
    }
    return <footer className='share-footer'>
        <img src={require('assets/images/appimg/foot-logo.png')} alt="logo"/>
        <div className='footer-rt'>
            <div>
                <div className='title'>源自麻省理工学院</div>
                <div className='title2'>发现改变世界的新兴科技</div>
            </div>
            {/* <p>By 胡玮炜 / 科学界</p> */}
            <button onClick={openAppCheck}>打开</button>
        </div>
    </footer>
}
export default FooterShare