import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import { Skeleton } from 'antd'
import 'styles/information/detail.scss'
import Auther from 'comps/Auther'
import HotNews from 'comps/information/HotNews'
import SearchItem from 'comps/search/SearchItem'
import Avatar from 'comps/Avatar'
import fromatTime from 'utils/formatTime'
interface stateType{
    id: number,
    detailInfo: object,
    detailLoad: boolean,
    recommendList: any[],
    recommendLoad: boolean,
    recommendData: any[]
}
interface propsType{
    match: any,
    getBannerList: any[]
}
class InformationDetail extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            detailInfo:{},
            detailLoad: true,
            recommendList: [],
            recommendLoad: true
        }


    }
    
    componentDidMount(){
        this.getDetail()
        this.getRecommend()
    }

    //详情
    getDetail = () => {
        let id = this.state.id
        this.axios.get('/information/details', {params:{id}}).then(res => {
            if (res.code === 10000) {
                this.setState({
                    detailInfo: res.data,
                    detailLoad: false
                })
                document.title = '麻省理工科技评论-' + res.data.name
            }
        })
    }

    //更多推荐
    getRecommend = () => {
        let id = this.state.id
        this.axios.get('/information/recommend', {params:{id}}).then(res => {
            this.setState({
                recommendList: res.data,
                recommendLoad: false,
                recommendData: res.data.items && res.data.items.slice(0,4)
            })
        })
    }
    render() {
        const {detailInfo, detailLoad, recommendList, recommendLoad, recommendData} = this.state
        const {getBannerList} = this.props
        return (
            
            <div className="detail-con">
                <Skeleton active loading={detailLoad}>
                    <div className="title-con">
                        <div className="titleleft">
                            <div className="type-title">{detailInfo.typeName}</div>
                            <div className="title">{detailInfo.name}</div>
                            <div className="little-title" title={detailInfo.summary}>{detailInfo.summary}</div>
                        </div>
                        <img src={detailInfo.cover + '?imageView2/2/w/504/h/280'} alt=""/>
                        {/* <Avatar photoUrl={detailInfo.cover} photoWidth={'504px'} photoHeight={'280px'} isRadius={false}></Avatar> */}
                    </div>
                    <div className="article-con">
                        <div className="article-left">
                            <div className="content"  dangerouslySetInnerHTML={{ __html: detailInfo.content && detailInfo.content.replace(/<img/g, '<img style="max-width:636px;object-fit:contain"') }} ></div>
                            <div className="time">{fromatTime(detailInfo.start_time * 1000)}</div>
                            {/* <div className="share-box">
                                <span>分享到：</span>
                                <img src={require('assets/images/weixin.svg')} alt="" className="share-weixin"/>
                                <img src={require('assets/images/weibo.svg')} alt="" className="share-weibo"/>
                            </div> */}
                            <Auther autherData={detailInfo.authors && detailInfo.authors[0]}></Auther>
                        </div>
                        <div className="article-right">
                            <div className="news-con">
                                <div className="little-title">本周热文</div>
                                <div className="newlist">
                                    <HotNews></HotNews>
                                </div>
                            </div>
                            {
                                getBannerList.sidebar ? getBannerList.sidebar && getBannerList.sidebar.url ? <a href={getBannerList.sidebar.url}><img src={getBannerList.sidebar && getBannerList.sidebar.img} alt="" className="banner" /></a> : <img src={getBannerList.sidebar && getBannerList.sidebar.img} alt="" className="banner" /> : null
                            }
                            {/* <a href={this.props.getBannerList[2].url}><img src={this.props.getBannerList[2].img} alt="" className="banner"/></a> */}

                        </div>
                    </div> 
                </Skeleton>
                <Skeleton active loading={recommendLoad}>
                    <div className="recommend-list">
                        <div className="more">更多推荐</div>
                        <div className="recommend-con">
                            {
                                recommendData && recommendData.map(item => {
                                    return  <SearchItem dataList={item} type={'news'}></SearchItem>
                                })
                            }
                            {
                                getBannerList.middle ? getBannerList.middle && getBannerList.middle.url ? <a href={getBannerList.middle.url}><img src={getBannerList.middle && getBannerList.middle.img} alt="" className="reimg"/></a> : <img src={getBannerList.middle && getBannerList.middle.img} alt="" className="reimg"/> : null 
                            }
                            {/* <img src={getBannerList[1].img} alt="" className="reimg"/> */}
                            <SearchItem dataList={recommendList.items && recommendList.items[4]}  type={'news'}></SearchItem>
                        </div>
                    </div>
                </Skeleton>
                
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        getBannerList: state.getBannerList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(withRouter(InformationDetail))

