// 错误码
export const ERROR_CODE = {
    "10000": "成功",
    "10001": "失败",
    "20000": "缺少参数",
    "30000": "已存在",
    "20001": "登录信息已过期，请重新登录！", // 后台返回的是 没有TOKEN
    "20002": "验证码错误",
    "20003": "密码错误",
    "20004": "用户已禁止访问",
    "20005": "不是手机号",
    "20006": "发送短信失败",
    "20007": "没有找到用户",
    "20008": "上传失败",
    "20009": "没有找到相关数据",
    "20010": "没有权限",
    "20011": "没有设备编码",
    "20012": "用户没有登录",
    "20013": "参数错误",
    "20014": "没有关注过",
    "20016": "邮件发送错误",
    "99999": "超出频率",
    "20018": "上传失败,空文件或不是Excel文件",
    "20019": "当前用户使用期限已过，请联系网站管理人员",
    "20020": "用户未生效",
    "20021": "两次密码不对",
    "20022": "用户原密码不对",
    "20023": "用户原密码与新密码一样",
    "20024": "园区订单有未完成订单",
    "20025": "搜索次数已达上限",
    "20026": "当前专辑的内容数量已达上限！",
    "20027": "用户手机号已存在！",
    "20028": "用户邮箱号已存在！",
}


