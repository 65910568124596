
import React, { Component } from 'react'
import 'styles/components/hotnews.scss'
import Avatar from '../Avatar'
import HotItem from './HotItem'
interface stateType{
    hotNews:any[]
}
interface propsType{
}
class HotNews extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            hotNews:[]
        }

    }
    
    componentDidMount(){
        this.getHotNews()
    }
    //本周热文
    getHotNews = () => {
        this.axios.get('/information/hot',{params:{limit:5}}).then(res => {
            if (res.code === 10000){
                this.setState({
                    hotNews: res.data
                })
            }
        })
    }
    render() {
        const { hotNews } = this.state
        return (
            <>
                {
                    hotNews && hotNews.map((item, index) => {
                        return  <HotItem item={item} index={index} key={index}></HotItem>
                        
                    })
                }
            </>
        )
    }
}
export default HotNews

