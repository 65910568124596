
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {withRouter} from 'react-router-dom'
import 'styles/information/information.scss'
import Subscribe from 'comps/subscribe/Subscribe'
import HotNews from 'comps/information/HotNews'
import Auther from 'comps/Auther'
import fromatTime from 'utils/formatTime'
import { getBanner } from '../../redux/banner/action'
import Avatar from 'comps/Avatar'
import className from 'classname'
import LastItem from 'comps/information/LastItem'
import FourItem from 'comps/information/FourItem'
import ListItem from 'comps/information/ListItem'
import { Skeleton } from 'antd'
interface stateType{
    params: {
        page:number,
        limit:number,
        author: string,
        type: string,
        label: string,
        is_ad: boolean
    },
    information: any[],
    information2: any[],
    informationList: any[],
    total: number,
    hotNews: any[],
    bannerList: object,
    flashList: any[],
    infoCover: object,
    load: boolean,
}
interface propsType{
    getBanners: Function
}
class Information extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            params:{
                page:4,
                limit:4,
                author: '',
                type: '',
                label: '',
                is_ad: false
            },
            information: [],
            information2: [],
            informationList: [],
            total: 0,
            hotNews: [],
            bannerList:{},
            flashList:[],
            infoCover:{},
            load: false,
        }

    }
    
    componentDidMount(){
        this.getInformation()
        // this.getInformationList()
        this.getBanner()
        this.getFlash()
        this.getInfoCover()
        this.getInformationListDefault()
    }

    //获取最新、横向四个资讯列表
    getInformation = () => {
        // console.log('this',this)
        let params = Object.assign({}, this.state.params, { page: 1 })
        let params2 = Object.assign({}, this.state.params, { page: 2 })
        this.axios.get('/information/index', {params}).then(res => {
            if (res.code === 10000) {
                this.setState({
                    information: res.data.items
                })
            }
        })
        this.axios.get('/information/index', {params: params2}).then(res => {
            if (res.code === 10000) {
                this.setState({
                    information2: res.data.items
                })
            }
        })
    }

    //获取资讯列表
    getInformationList = () => {
        let params =  Object.assign({}, this.state.params, { is_ad: true })
        let informationList = this.state.informationList
        this.axios.get('/information/index', {params}).then(res => {
            if (res.code === 10000) {
                let items = Object.assign({}, {items: res.data.items}, { ad: res.data.ad })
                informationList.push(items)
                // console.log('informationList', informationList)
                this.setState({
                    informationList,
                    total: res.data.total
                })
            }
        })
    }

    //获取默认资讯列表
    getInformationListDefault = () => {
        let params =  Object.assign({}, this.state.params, { is_ad: true },{page: 2},{limit: 8})
        let informationList = []
        this.axios.get('/information/index', {params}).then(res => {
            if (res.code === 10000) {
                // console.log(res.data.items)
                let ite = res.data.items
                informationList.push({items: ite.slice(0,4)},{items: ite.slice(4),ad: res.data.ad})
               
                this.setState({
                    informationList,
                    total: res.data.total
                }, () => {
                    // console.log('state', this.state.informationList)
                })
            }
        })
    }

    //加载更多
    loadMore = () => {
        let page = this.state.params.page
        page ++
        let params = Object.assign({}, this.state.params, { page })
        this.setState({
            params
        }, () => {
            this.getInformationList()
        })
    }

    //banner
    getBanner = () => {
        this.axios.get('/banner/index').then(res => {
            if (res.code === 10000){
                this.setState({
                    bannerList: res.data
                }, ()=> {
                    this.props.getBanners(this.state.bannerList)
                })
            }
        })
    }

    //快讯
    getFlash = () => {
        this.axios.post('/flash',{page:1 ,size:5}).then(res => {
            if (res.code === 10000){
                let flashList = res.data.items
                this.setState({
                    flashList
                })
            }
        })
    }

    //资讯封面
    getInfoCover = () => {
        this.axios.get('/information/cover').then(res => {
            if (res.code === 10000){
                this.setState({
                    infoCover: res.data
                })
            }
        })
    }

    //封面图片加载
    loadSuccess = () => {
        this.setState({
            load: true
        })
    }

    render() {
        const { information, information2, informationList, total, bannerList, flashList, infoCover, load} = this.state

        return (
            <div className="information-con">
                {
                    bannerList.top ? bannerList.top && bannerList.top.url ? <a href={bannerList.top.url}><img src={bannerList.top && bannerList.top.img} alt="" className="advertisement"/></a> : <img src={bannerList.top && bannerList.top.img} alt="" className="advertisement"/> : null
                }
                {/* <a href={bannerList[0] && bannerList[0].url}><img src={bannerList[0] && bannerList[0].img} alt="" className="advertisement"/></a> */}
                <div className="content">
                    <div className="cover-flash">
                        <div className='cover-flash-left'>
                            <a href={`/news/detail/${infoCover.id}`}>
                                <div className="cover">
                                    <div className="cover-con">
                                        <div className="cover-border"></div>
                                        <div className="fm">封面</div>
                                        <div className="title" title={infoCover.name}>{infoCover.name} </div>
                                        <div className="con" title={infoCover.summary}>{infoCover.summary}</div>
                                    </div>
                                    <div className="img-box">
                                        
                                        <img src={infoCover.cover  + '?imageView2/2/w/735/h/408'} alt="" className="coverImg" onLoad={this.loadSuccess}/>
                                        {
                                            !load ? <Skeleton active className="loadingImg"/> : null
                                        }
                                    </div>
                                </div>
                            </a>
                            <div className="lastes-con">
                                <span className="little-title">最新</span>
                                <div className="lastest-list">
                                    {
                                        information && information.map((item, index) => {
                                            return <LastItem item ={item} key={index}></LastItem>
                                        })
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="flash">
                            {/* <a href="https://mp.weixin.qq.com/s/aGBi5AClneuHBXVv0JDk5Q" target="_blank">
                                <img src={require('assets/images/scifilter.jpg')} alt="" className='papergpt-banner' />
                            </a> */}
                            <Subscribe></Subscribe>
                            <div className="flash-list">
                                <div className="title">
                                    <span className="little-title">快讯</span>
                                    <a href="/breaking">查看更多</a>
                                </div>
                                <div className="list">
                                    {
                                        flashList.map((item, index) => {
                                            // return <a href={`/breaking#${item.id}`}><div className="item">{item.name}</div></a>
                                            return <div className="item" onClick={() => this.props.history.push({pathname:'/breaking', state:[{id: 'item' + item.id}]})} title={item.name} key={index}>{item.name}</div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="news-con">
                                <div className="little-title">本周热文</div>
                                <div className="newlist">
                                    <HotNews></HotNews>
                                </div>
                                <div className="title">
                                    <span className="little-title">我们的团队</span>
                                    <a href="/about">查看更多</a>
                                </div>
                                <Auther pageSize={2}></Auther>
                            </div>
                        </div>
                    </div>
                    <div className="lastest-news">
                        {/* <div className="lastes-con">
                            <span className="little-title">最新</span>
                            <div className="lastest-list">
                                {
                                    information && information.map((item, index) => {
                                        return <LastItem item ={item} key={index}></LastItem>
                                    })
                                }
                                
                            </div>
                        </div> */}
                        {/* <div className="news-con">
                            <div className="little-title">本周热文</div>
                            <div className="newlist">
                                <HotNews></HotNews>
                            </div>
                            <div className="title">
                                <span className="little-title">我们的团队</span>
                                <a href="/about">查看更多</a>
                            </div>
                            <Auther></Auther>
                        </div> */}
                    </div>
                    {
                        bannerList.middle ? bannerList.middle && bannerList.middle.url ? <a href={bannerList.middle.url}><img src={bannerList.middle && bannerList.middle.img} alt="" className="advertisement2"/></a> : <img src={bannerList.middle && bannerList.middle.img} alt="" className="advertisement2"/> : null 
                    }
                    {/* <a href={bannerList[1] && bannerList[1].url}><img src={bannerList[1] && bannerList[1].img} alt="" className="advertisement2"/></a> */}
                    <div className="typelist">
                        
                        {
                            information2 && information2.map((item, index) => {
                                return  <FourItem item={item} key={index}></FourItem>
                            
                            })
                        }
                    </div>
                    <div className="inforlist">
                        {
                            informationList && informationList.map((ite, index) => {
                                return <ListItem ite={ite} key={index}></ListItem>
                            })
                        }
                        {
                            total > informationList.length * 4 + 8 ? <div className="loadbox"><div className="loadMore" onClick={this.loadMore}>加载更多</div></div> : null
                        }
                        
                    </div>
                </div>
                
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return {
        getBanners:data => {
            dispatch(getBanner(data))
        },

    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Information))

