
import React, { Component } from 'react'
import { Form, Modal, Button } from 'antd'
import { setStorage } from 'utils/storage'
import 'styles/user/user.scss'
import { connect } from 'react-redux'
import { getusers } from '@redux/user/action'
interface stateType{
    codeBtnisAble: boolean,
    codeBtnisAble2: boolean,
    codeText1: string, //手机验证
    codeText2: string, //手机绑定
    isTest: boolean,
    maxTime1: number,
    maxTime2: number,
    code: string,
    mobile: string,
    codeStatus: string
}
interface propsType{
    isVisible: boolean,
    clickCancel: Function,
    defaultMobile: string,
    getusers: Function,
}
@connect(state => state, { getusers })
class Phone extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            codeBtnisAble: false,
            codeBtnisAble2: false,
            codeText1: '获取验证码',
            codeText2: '获取验证码',
            isTest: false,
            maxTime1: 60,
            maxTime2: 60,
            code: '',
            mobile: '',
            codeStatus: ''
        }
    }

    componentDidMount(){
        // console.log('this.props.defaultMobile',this.props.defaultMobile)

    }

    //取消
    handleTestCancle = () =>{
        this.props.clickCancel()
    }

    //手机号校验
    firstChecked = (rule, value) => {
        const regPhone = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
        if (!value) {
            return Promise.reject('手机号不能为空')
        } else {
            if (!regPhone.test(value)) {
                return Promise.reject('手机号格式不正确 请重新输入')
            }
            else if (value === this.props.defaultMobile){
                return Promise.reject('请输入新手机号')
            }
        }
        return Promise.resolve()
    }

    //验证身份结束
    handleTestFinish = values => {
        console.log('allValues', values)
    }

    //验证身份更改input
    handleTestChange = (changedValues, allValues) => {
        console.log('changedValues', changedValues)
        this.setState({
            code: changedValues.code
        })
    }

    //验证身份获取验证码
    getCode = () => {
        let params = {
            mobile: this.props.defaultMobile,
            type: 2
        }
        this.axios.post('/code', params).then(res => {
            console.log(res)
            this.setState({ codeBtnisAble:true })
            let siv = setInterval(() => {
                this.setState( preState => ({
                    maxTime1: preState.maxTime1 - 1,
                    codeText1: `${preState.maxTime1 - 1}s`
                }), () => {
                    if (this.state.maxTime1 === 0) {
                        clearInterval(siv)
                        this.setState({
                            codeBtnisAble: false, codeText1: "重新获取验证码", maxTime1: 60
                        })
                    }
                })
            }, 1000)
        })
    }


    //确定验证
    handleTestOk = e => {
        let params = {
            mobile: this.props.defaultMobile,
            code: this.state.code,
        }
        this.state.code && this.axios.post('/isCode', params).then(res => {
            if (res.code === 10000){
                this.handleTestCancle()
                this.setState({
                    isTest: true,
                    code: ''
                })
            }
        })

    }

    //绑定手机取消操作
    handleCancle = () => {
        this.setState({
            isTest: false
        })
    }

    //绑定手机结束
    handleFinish = values => {
        console.log('allValues', values)
        this.handleOk(values)
    }

    //绑定手机更改input
    handleChange = (changedValues, allValues) => {
        // console.log('changedValues', allValues)
        let { mobile,code } = allValues
        this.setState({mobile,code})

    }

    //绑定手机获取验证码
    getCode2 = () => {
        let params = {
            mobile: this.state.mobile,
            type: 1
        }
        const regPhone = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
        if (this.state.mobile && regPhone.test(this.state.mobile) && this.state.mobile !== this.props.defaultMobile){
            this.axios.post('/code', params).then(res => {
                console.log(res)
                this.setState({ codeBtnisAble2:true })
                let siv = setInterval(() => {
                    this.setState( preState => ({
                        maxTime2: preState.maxTime2 - 1,
                        codeText2: `${preState.maxTime2 - 1}s`
                    }), () => {
                        if (this.state.maxTime2 === 0) {
                            clearInterval(siv)
                            this.setState({
                                codeBtnisAble2: false, codeText2: "重新获取验证码", maxTime2: 60
                            })
                        }
                    })
                }, 1000)
            })
        }
    }


    //确定绑定手机
    handleOk = values => {
        this.axios.post('/bindPhone', values).then(res => {
            if (res.code === 10000){
                this.setState({
                    isTest: false
                }, () => {
                    this.handleTestCancle()
                    this.props.getusers()
                })
            }
        })
    }
    render() {
        const {isVisible, defaultMobile} = this.props
        const {codeBtnisAble, codeBtnisAble2,codeText1, isTest, codeText2} = this.state
        return (
            <div>
                <Modal
                    className="phone"
                    title="身份验证"
                    okText="验证"
                    visible={isVisible}
                    width={566}
                    onCancel={this.handleTestCancle}
                    onOk={this.handleTestOk}
                    footer={null}
                    destroyOnClose
                >
                    <div className="content">
                        <div className="title">为了您的帐号安全，请先进行身份验证</div>
                        <Form  onFinish={this.handleTestOk} onValuesChange={this.handleTestChange}>
                            <Form.Item
                                className='item'
                                name='testmobile'
                            >
                                <input type="text" placeholder={`请使用手机号${defaultMobile}验证`} disabled/>
                            </Form.Item>
                            <Form.Item
                                className='item'
                                name='code'
                                rules={[{required:true, message:'短信验证码不能为空'}]}
                                validateTrigger='onChange'
                                // initialValue={code}
                            >
                                <div>
                                    <input type="text" placeholder="请输入验证码" />
                                    <Button className='vercode' onClick={this.getCode} disabled={codeBtnisAble}>{codeText1}</Button>
                                </div>
                            </Form.Item>
                            <button type="submit" className="yesok">验证</button>
                        </Form>
                    </div>
                </Modal>

                <Modal
                    className="phone"
                    title="绑定手机"
                    okText="确定"
                    visible={isTest}
                    // visible={true}
                    width={566}
                    onCancel={this.handleCancle}
                    // onOk={this.handleOk}
                    footer={null}
                    destroyOnClose
                >
                    <div className="content">

                        <Form  onFinish={this.handleFinish} onValuesChange={this.handleChange}>
                            <Form.Item
                                className='item'
                                name='mobile'
                                rules={[{ validator: this.firstChecked }]}
                                validateTrigger='onChange'
                            >
                                <input type="text" placeholder="请输入新手机号" />
                            </Form.Item>
                            <Form.Item
                                className='item'
                                name='code'
                                rules={[{required:true, message:'短信验证码不能为空'}]}
                                validateTrigger='onChange'
                            >
                                <div>
                                    <input type="text" placeholder="请输入验证码" />
                                    <Button className='vercode' onClick={this.getCode2} disabled={codeBtnisAble2}>{codeText2}</Button>
                                </div>
                            </Form.Item>
                            <button type="submit" className="yesok">确定</button>
                        </Form>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default Phone

