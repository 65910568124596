
import React, { Component } from 'react'
import 'styles/components/videoitem.scss'
import fromatTime from 'utils/formatTime'
import { Skeleton } from 'antd'
interface stateType{
    load: boolean
}
interface propsType{
    item: any
}
class SlideItem extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            load: false
        }

    }
    
    componentDidMount(){
    }

    loadSuccess = () => {
        this.setState({
            load: true
        })
    }
    render() {
        const {item} = this.props
        const {load} = this.state
        return (
            <div className="swiper-slide">
                <div className="video">
                    <a href={`/video/detail/${item.id}`}>
                        <img src={item.img + '?imageView2/2/w/735/h/413'} className="hotvideo" alt="" onLoad={this.loadSuccess}/>
                        <div className="videoPlay"></div>
                    </a>
                    {
                        !load ? <Skeleton active className="loadingImg"/> : null
                    }
                </div>
                <div className="video-intro">
                    <div className="intro">
                        <div className="introcon">推荐</div>
                    </div>
                    <a href={`/video/detail/${item.id}`}>
                        <div className="video-title" title={item.title}>
                            {item.title}
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}
export default SlideItem

