import React from 'react'
import 'styles/h5share/agree.scss'

const Privacy = () => {
    return <section className='h5agree privacy'>
        <div className='preface'>
        北京演绎科技有限公司 （以下简称“ 本APP经营者”）非常重视您的个人信息保护，会依法保护您的个人信息，同时制定严格的政策来管理所有数据。使用本APP服务前，您需要同意本隐私政策中关于本APP经营者如何收集、使用、分享和保护您的相关信息的规定。如果您不同意本隐私政策中关于基本业务功能的相关内容，可能将影响为您提供相应产品和/或服务的效果。<br/>
        本隐私政策与您使用本APP服务关系紧密，请您仔细阅读并理解本隐私政策的全部内容。<br/>
        为了遵守国家法律法规及监管规定，也为了向您提供服务及提升服务质量，本APP经营者需要收集、存储、使用及对外提供您的信息。您同意本APP经营者按照本隐私政策约定处理您的信息，以便您享受优质、便捷、个性化的服务，同时更好地保护您的账号安全。<br/>
        </div>
        <div className="title">一、本APP经营者如何收集和使用您的个人信息<br/></div>
        为了保证本APP用户注册、账户和服务安全、客户服务等各项基本业务功能及提供各项扩展业务功能的正常运行，本APP经营者会收集和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为本APP的用户或无法使用和享受本APP提供的某些产品和服务，或者无法达到相关产品和/或服务拟达到的效果。<br/>
        1、基本业务功能<br/>
        （1）本APP用户注册。为创建账号，本APP经营者收集并使用您的手机号码或微信、微博等第三方账号信息（头像、昵称以及您授权的其他信息）。如果您不同意授权或拒绝本APP经营者获取上述信息，您将可能无法享受本APP相关的功能和服务。您可以完善相关的网络身份识别信息（如头像、昵称等）和个人信息（性别、生日、地区、个人简介等）。<br/>
        （2）播放浏览<br/>
        为向您推荐可能感兴趣的频道并保障推荐内容的质量，本APP经营者可能会收集相关必要的日志信息，包括：<br/>
        您操作、使用的行为信息：点击、关注、收藏、搜索、浏览、分享；<br/>
        您主动提供的信息：反馈、发布、好奇、评论、语音、录音、录像；<br/>
        地理位置信息： WLAN接入点、蓝牙和基站等传感器信息。<br/>
        （3）信息发布<br/>
        您发布语音、录音、直播、视频等内容或进行评论时，本APP经营者将收集您发布的信息，并展示您的昵称、头像、发布内容和信息。<br/>
        本APP经营者可能收集用户因使用本APP产品或者服务而产生的信息，例如其他用户发布的信息中可能含有您的部分信息（如：在评论、留言、发布音频中涉及到与您相关的信息）。<br/>
        （4）互动交流<br/>
        当您关注账号、进行浏览、评论、关注、好奇或分享时，本APP经营者会收集您关注的账号和前述信息，并展示关注账号发布内容。<br/>
        （5）搜索<br/>
        您使用本APP的搜索服务时，本APP经营者会收集您的搜索关键字信息、日志记录。为了提供高效的搜索服务，部分前述信息会暂时存储在您的本地存储设备之中，并可向您展示搜索结果内容、搜索历史记录。<br/>
        （6）账户和服务安全<br/>
        为进行运营商认证、安全防范、诈骗检测、存档和备份等活动，确保向您提供的产品和服务的安全性，本APP经营者将收集并使用您的硬件型号、操作系统版本号、国际移动设备识别码、唯一设备标识符、网络设备硬件地址、IP 地址、WLAN接入点、蓝牙、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。<br/>
        为了预防恶意程序及安全运营所必需，本APP经营者会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。<br/>
        （7）客户服务<br/>
        为保障您的账号安全，本APP在向您提供客户服务时可能会使用您的账号信息来核实您的身份；在您需要反馈问题时，本APP会查询您的服务使用记录帮助您解决产品或服务问题。您在与本APP客服或服务人员进行沟通、咨询时，本APP会对沟通进行记录。<br/>
        2、拓展业务功能<br/>
        （1）实名认证<br/>
        在您使用身份认证的功能或相关服务所需时，根据相关法律法规，本APP经营者可能收集您的真实身份信息（真实姓名、身份证号码、面部特征等生物识别信息）以完成实名验证。 真实身份信息属于个人敏感信息，您可以拒绝提供，如果拒绝提供您将可能无法获得相关服务，但不影响其他功能与服务的正常使用。<br/>
        （2）相机、麦克风等授权信息<br/>
        您使用上传头像/图片、语音通话、视频通话等功能时，本APP经营者会请求您授权相机、相册（图片）、麦克风、蓝牙等权限。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用本APP的其他功能。<br/>
        上述敏感信息均不会默认开启，只有在您明确同意后才会向本APP授权，即使本APP已经获得您的敏感信息权限，也仅是在本隐私政策项下收集、使用您的信息。<br/>
        3、收集、使用个人信息目的的变更<br/>
        当本APP经营者要将前述收集的个人信息用于本隐私政策未载明的其它用途时，会事先征求您的同意。<br/>
        4、依法豁免征得同意收集和使用的个人信息<br/>
        您理解并确认，在下列情形中，根据法律法规及相关国家标准，本APP经营者收集和使用您的个人信息无需征得您的授权同意：<br/>
        （1）  与国家安全、国防安全直接相关的；<br/>
        （2）  与公共安全、公共卫生、重大公共利益直接相关的；<br/>
        （3）  与犯罪侦查、起诉、审判和判决执行等直接相关的；<br/>
        （4）  出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
        （5）  所收集的您的个人信息是您自行向社会公众公开的；<br/>
        （6）  从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；<br/>
        （7）  根据您的要求签订或履行协议所必需的；<br/>
        （8）  用于维护软件及相关服务的安全稳定运行所必需的，例如发现、处置软件及相关服务的故障；<br/>
        （9）  为合法的新闻报道所必需的；<br/>
        （10）  学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；<br/>
        （11）  法律法规规定的其他情形。<br/>
        特别提示您注意，如信息无法单独或结合其他信息识别到您的个人身份，其不属于法律意义上您的个人信息；当您的信息可以单独或结合其他信息识别到您的个人身份时或我们将无法与任何特定个人信息建立联系的数据与其他您的个人信息结合使用时，这些信息在结合使用期间，将作为您的个人信息按照本隐私政策处理与保护。<br/>

        <div className="title">二、本APP经营者对Cookies和同类型技术的使用<br/></div>
        Cookies和同类型技术是互联网中的通用常用技术。当您使用本APP相关服务时，本APP经营者可能会使用相关技术向您的设备发送一个或多个Cookies或匿名标识符，以收集和存储您访问、使用本APP经营者服务时的信息。本APP经营者使用 Cookies 和同类技术主要为了实现以下功能或服务：<br/>
        1、保障产品与服务的安全、高效运转<br/>
        本APP经营者可能会设置认证与保障安全性的Cookies或匿名标识符，以确认您是否安全登录服务，或者是否遭受盗用、欺诈等不法行为。这些技术还会帮助本APP经营者改进服务效率，提升登录和响应速度。<br/>
        2、帮助您获得更轻松的访问体验<br/>
        使用Cookies和同类型技术可以帮助您省去重复填写个人信息、判断您的登录状态以及账号或数据安全等。<br/>
        3、为您推荐、展示、推送您可能感兴趣的内容<br/>
        本APP经营者可能会利用 Cookies和同类技术了解您的偏好和使用习惯，进行咨询或数据分析，以改善产品服务及用户体验。 <br/>
        <div className="title">三、本APP经营者如何共享、转让及公开披露您的信息<br/></div>
        1、共享<br/>
        本APP经营者不会与其他组织、公司和个人共享您的个人信息，但以下情况除外：<br/>
        （1）共享原则<br/>
        未经您的同意，本APP经营者不会共享您的个人信息，除非共享的个人信息已去标识化处理，且被共享的第三方无法重新识别此类信息所属的自然人主体。本APP经营者仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享必要的个人信息。<br/>
        对本APP经营者与之共享个人信息的公司、组织和个人，本APP经营者会与其签署严格的保密协定或保密条款，要求他们按照本APP经营者的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。<br/>
        （2）实现功能或服务的共享信息<br/>
        请您理解并同意，为了使您能够享受到第三方登录、接受信息推送、使用位置服务、在第三方平台分享信息等平台服务和功能，本APP经营者可能会使用第三方代码、插件、SDK或相关技术为您提供更优质的服务。如本APP经营者为了打造绿色健康的网络服务环境，需同第三方合作伙伴收集、处理您所发布的音频数据，加以及时排查违法风险行为。本APP经营者及关联方、第三方仅会基于本隐私政策声明的合法、正当、必要目的收集、使用、共享您的信息，我们会对SDK或相关技术进行严格的安全监测，以保护您的数据安全。<br/>
        a.您在使用本APP中由本APP经营者的关联方、第三方提供的功能，或者当软件服务提供商、智能设备提供商、系统服务提供商与本APP经营者联合为您提供服务时本APP经营者会将实现业务所必需的信息与这些关联方、第三方共享，用于综合统计并通过算法做特征与偏好分析，形成间接人群画像，用以向您进行推荐、展示或推送您可能感兴趣的信息。<br/>
        b. 当您选择发布信息可以同步到第三方的产品或服务（微博、微信等）后，本APP经营者可能会使用SDK或相关技术与这些产品或服务的提供方共享发布的内容及评论、点赞信息。<br/>
        c. 为与您使用的终端机型适配消息推送功能，本APP经营者可能会通过SDK等技术与终端设备制造商（华为、小米、OPPO、VIVO等）共享手机型号、版本及相关设备信息。<br/><br/>
        特别提示：<br/><br/>

        本APP使用SDK名称：友盟SDK<br/>
        服务类型：消息分享<br/>
        收集个人信息类型：设备信息（IMEI/MAC/Android ID/IDFA/OpenUDID/GUID/SIM卡IMSI/地理位置等）<br/>
        友盟SDK隐私政策链接：<a href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a><br/>

        本APP 使用SDK名称: 个推消息推送SDK<br/>
        服务类型：消息推送<br/>
        收集个人信息类型：设备平台、设备厂商、设备品牌、设备识别码等设备信息，应用列表信息、网络信息以及位置相关信息<br/>
        个推用户隐私政策链接：<a href="https://docs.getui.com/privacy">https://docs.getui.com/privacy</a> <br/><br/>

        （3）法定情形下的共享<br/>
        本APP经营者可能会根据法律法规规定、诉讼争议解决需要或者行政、司法等有权机关依法提出的要求对外共享您的个人信息。<br/>

        2、转让<br/>
        本APP经营者不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br/>
        （1）在获取明确同意的情况下转让：获得您的明确同意后，本APP经营者会向其他方转让您的个人信息；<br/>
        （2）在涉及合并、收购或破产清算时，如涉及到个人信息转让，本APP经营者会在要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则本APP经营者将要求该公司、组织重新向您征求授权同意。<br/>
        3、公开披露<br/>
        本APP经营者仅会在以下情况下，公开披露您的个人信息：<br/>
        （1）获得您明确同意或基于您的主动选择，本APP经营者可能会公开披露您的个人信息；<br/>
        （2）如果您出现违反法律法规或严重违反本隐私政策的情况，或为保护用户或公众的人身财产安全免遭侵害，本APP经营者可能依据法律法规或本隐私政策披露您的相关个人信息，包括相关违规行为以及本APP经营者已对您采取的措施。<br/>
        4、不得披露<br/>
        （1）任何情况下，本APP经营者不会公开披露您的个人生物识别信息等个人生理信息；<br/>
        （2）任何情况下，本APP经营者不会公开披露您的种族、民族、政治观点、宗教信仰等敏感数据。<br/>
        5、共享、公开披露个人信息时事先征得授权同意的例外<br/>
        以下情形中，共享、公开披露您的个人信息无需事先征得您的授权同意：<br/>
        （1）与国家安全、国防安全有关的；<br/>
        （2）与公共安全、公共卫生、重大公共利益有关的；<br/>
        （3）与犯罪侦查、起诉、审判和判决执行等有关的；<br/>
        （4）出于维护您或其他个人的生命、财产等重大合法权益但因主客观原因很难征得您本人同意的；<br/>
        （5）您自行向社会公众公开的个人信息；<br/>
        （6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。<br/>

        <div className="title">四、 本APP经营者如何存储您的个人信息<br/></div>
        1、存储地点<br/>
        本APP经营者依照法律法规的规定，将在境内运营过程中收集和产生的您的个人信息存储于中华人民共和国境内。目前，本APP经营者不会将上述信息传输至境外，如果向境外传输，本APP经营者将会遵循相关国家规定或者征求您的同意。<br/>
        2、存储期限<br/>
        本APP经营者仅在为提供服务之目的所必需的期间内保留您的个人信息：您发布的音频信息、评论、喜欢（点赞）及相关信息，在您未撤回、删除或未注销账号期间，本APP经营者会保留相关信息。超出必要期限后，本APP经营者将对您的个人信息进行删除或匿名化处理，但法律法规另有规定的除外。<br/>
        <div className="title">五、本APP经营者如何保护您的个人信息<br/></div>
        1、本APP经营者已使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。本APP经营者会采取一切合理可行的措施，保护您的个人信息。<br/>
        2、本APP经营者仅在本隐私政策所述目的范围内和法律法规要求的时限内保留您的个人信息。本APP经营者使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，本APP经营者将利用加密技术来保护您提供的个人信息。<br/>
        3、互联网并非绝对安全的环境，而且电子邮件、即时通讯及其他交流方式并未加密，本APP经营者强烈建议您不要通过此类方式发送个人信息。请使用复杂密码，协助本APP经营者保证您的账号安全。请您谅解，由于技术的限制以及风险防范的局限性，即便本APP经营者已经尽量加强安全防护措施，也无法始终保证信息绝对的安全。您需要了解，您接入本APP经营者的服务所用的系统和通讯网络，有可能因本APP经营者可控范围外的情况而发生问题。<br/>
        4、如不幸发生个人信息安全事件，本APP经营者将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、本APP经营者已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。本APP经营者将及时将事件相关情况以邮件、信函、电话、在线客服、推送通知等方式告知您，难以逐一告知个人信息主体时，本APP经营者会采取合理、有效的方式发布公告。<br/>

        <div className="title">六、您的权利<br/></div>
        按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，本APP经营者保障您对自己的个人信息行使以下权利：<br/>
        1、访问您的个人信息<br/>
        您有权访问您的个人信息，法律法规规定的例外情况除外。如果您想行使数据访问权，可以通过以下方式自行访问：<br/>
        如果您希望访问或编辑您的账户中的账户信息，您可以通过访问头像、用户名等基本资料进行访问、更正。对于您在使用本APP经营者产品或服务过程中产生的其他个人信息，只要本APP经营者不需要过多投入，我们会向您提供。<br/>
        如果您无法通过上述操作访问个人信息或希望行使数据访问权，您可以随时联系本APP经营者，本APP经营者会在30日内回复您的访问请求。<br/>
        2、查询访问、更正、删除信息<br/>
        您可以按照本APP的流程访问、删除您发布的音频信息及您通过点赞（喜欢）等操作形成的其他信息。<br/>
        您可以按照本APP的流程访问、查询关注的账号信息，进入关注账号主页可以选择取消关注账号。<br/>
        您可以按照本APP的流程访问本APP账号等信息、访问更正绑定的手机号、第三方账号等。<br/>
        出于安全性和身份识别的考虑，您可能无法自主修改注册时提交的某些初始注册信息（如实名认证后信息）；如您确有必要修改该类信息，请您联系本APP经营者，本APP经营者将尽快审核所涉问题，并由专人验证您的用户身份后及时予以回复。<br/>
        3、删除您的个人信息<br/>
        在以下情形中，您可以向本APP经营者提出删除个人信息的请求：<br/>
        （1）本APP经营者处理个人信息的行为违反法律法规；<br/>
        （2）本APP经营者收集、使用您的个人信息，却未征得您的同意；<br/>
        （3）本APP经营者处理个人信息的行为违反了与您的约定；<br/>
        （4）您不再使用本APP产品或服务，或您注销了账号；<br/>
        （5）本APP经营者不再为您提供产品或服务。<br/>
        若本APP经营者决定响应您的删除请求，还将同时通知从本APP经营者处获得您的个人信息的实体，要求其及时删除（法律法规另有规定，或这些实体获得您独立授权的除外）。<br/>
        当您从本APP中删除信息后，本APP经营者可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。<br/>
        4、改变您授权同意的范围<br/>
        每个业务功能需要一些基本的个人信息才能得以完成（见本隐私政策“第一条”）。对于额外收集的个人信息，您可以随时给予或收回您的授权同意。收回授权同意可能影响您使用某些产品或服务，本APP经营者会在您收回授权同意时向您说明。<br/>
        您可以通过第十二条中罗列的方式提出收回同意申请。当您收回同意后，本APP经营者将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。<br/>
        5、响应您的上述请求<br/>
        为保障安全，您可能需要提供书面请求或以其他方式证明您的身份。本APP经营者将先要求您验证自己的身份后再处理您的请求。<br/>
        对于您的合理请求，本APP经营者原则上不收取费用，但对多次重复、超出合理限度的请求，本APP经营者将视情况收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际的请求，本APP经营者可能会予以拒绝。<br/>
        在以下情形中，本APP经营者将无法响应您的请求：<br/>
        （1）与本APP经营者履行法律法规规定的义务相关的；<br/>
        （2）与国家安全、国防安全直接相关的；<br/>
        （3）与公共安全、公共卫生、重大公共利益直接相关的；<br/>
        （4）与犯罪侦查、起诉、审判和执行判决等直接相关的；<br/>
        （5）本APP经营者有充分证据表明个人信息主体存在主观恶意或滥用权利的；<br/>
        （6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；<br/>
        （7）响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；<br/>
        （8）涉及商业秘密的。<br/>

        <div className="title">七、未成年人的信息保护<br/></div>
        本APP经营者非常重视对未成年人个人信息的保护。若您是未满18周岁的未成年人，在使用本APP及相关服务前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。<br/>
        本APP经营者根据国家相关法律法规的规定保护未成年人的个人信息，只会在法律允许、父母或其他监护人明确同意或保护未成年人所必要的情况下收集、使用、共享或披露未成年人的个人信息；如果本APP经营者发现在未事先获得可证实的父母或其他监护人同意的情况下收集了未成年人的个人信息，则会设法尽快删除相关信息。<br/>
        若您是未成年人的监护人，当您对您所监护的未成年人的个人信息有相关疑问时，请联系本APP经营者。 <br/>
        <div className="title">八、本APP经营者向您发送的信息<br/></div>
        1、信息推送<br/>
        您在使用本APP服务时，本APP经营者可能向您发送电子邮件、短信、资讯或推送通知等。<br/>
        2、与服务有关的公告<br/>
        本APP经营者可能在必要时（例如，因系统维护而暂停某一项服务时）向您发出与服务有关的公告。<br/>
        <div className="title">九、本APP服务中的第三方服务<br/></div>
        本APP服务可能包括链接至第三方提供的其他服务。该等第三方服务可能由相关的第三方或本APP经营者运营。您使用该等第三方的服务（包括您向该等第三方提供的任何个人信息），须受第三方自己的服务条款及隐私政策约束，您需要仔细阅读其条款。本隐私政策仅适用于本APP经营者所收集的任何信息，并不适用于任何第三方提供的服务或第三方的信息使用规则，而本APP经营者对任何第三方使用由您提供的信息不承担任何责任。 <br/>
        <div className="title">十、适用范围<br/></div>
        本APP所有的服务均适用本隐私政策。请您注意，本隐私政策不适用于以下情况：通过本APP服务而接入的第三方服务收集的信息；通过在本APP服务中提供其他服务的第三方所收集的信息。 <br/>
        <div className="title">十一、本隐私政策的修改<br/></div>
        本APP经营者可能随时修改本隐私政策的条款，该等修改构成本隐私政策的一部分。如该等修改造成您在本隐私政策下权利的实质减少，本APP经营者将在修改生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您，在该种情况下，若您通过点击或勾选等方式同意或继续使用本APP经营者的服务，即表示您同意受经修订的本隐私政策约束。<br/>
    </section>
}
export default Privacy