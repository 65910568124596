
import React, { Component } from 'react'
import { Form, Input } from 'antd'
import { connect } from 'react-redux'
import 'styles/user/user.scss'
import Email from './Email'
import Avatar from './Avatar'
import Password from './Password'
import Phone from './Phone'
import className from 'classname'
import { getusers } from '@redux/user/action'

interface stateType{
    avatar: string,
    mobile: string,
    email: string,
    password: string,
    avatarVisible: boolean,
    phoneVisible: boolean,
    emailVisible: boolean,
    passwordVisible: boolean,
    nick_name: string,
    nickNameVisible: boolean
}
interface propsType{
    defaultUsers: any,
    clickCancel:Function,
    getusers:Function
}
@connect(state => state, { getusers })
class User extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            avatar: '',
            mobile: '',
            email: '',
            password: '',
            nick_name: '',
            avatarVisible: false,
            phoneVisible: false,
            emailVisible: false,
            passwordVisible: false,
            nickNameVisible: false
        }
    }

    componentDidMount(){
        // console.log('this.props.defaultUsers', this.props.defaultUsers)
        let {avatar, mobile, email, nick_name} = this.props.defaultUsers
        this.setState({
            avatar,
            mobile,
            email,
            nick_name,
        })

    }

    componentDidUpdate(preProps, state){
        if (preProps.defaultUsers !==  this.props.defaultUsers){
            let {avatar, mobile, email, nick_name} = this.props.defaultUsers
            this.setState({
                avatar,
                mobile,
                email,
                nick_name,
            })
        }
    }

    //编辑头像
    clickAvatar = () =>{
        this.setState({
            avatarVisible: true
        })
    }

    //编辑手机
    clickPhone = () => {
        this.setState({
            phoneVisible: true
        })
    }

    //编辑邮箱
    clickEmail = () => {
        this.setState({
            emailVisible: true
        })
    }

    //编辑密码
    clickPwd = () => {
        this.setState({
            passwordVisible: true
        })
    }

    //取消
    clickCancel = () => {
        this.setState({
            avatarVisible: false,
            phoneVisible: false,
            emailVisible: false,
            passwordVisible: false
        })
    }

    handleFinish = values => {
        console.log('allValues', values)
    }

    handleChange = (changedValues, allValues) => {
        // console.log('changedValues', changedValues)
        let {nick_name} = changedValues
        this.setState({nick_name})
    }

    //昵称校验
    nameChecked = (rule, value) => {
        if (!value) {
            return Promise.reject('用户昵称不能为空')
        }  else if (value.length > 20){
            return Promise.reject('用户昵称超出长度限制')
        }
        return Promise.resolve()
    }

    //点击编辑昵称按钮
    clickNickName = () => {
        this.setState({
            nickNameVisible: true
        })
    }

    //编辑昵称完成
    handleBlur = () => {
        this.setState({
            nickNameVisible: false
        }, () => {
            console.log(this.state.nick_name)
            this.changeNickName()
        })
    }

    //编辑昵称回车
    pressEnter = e => {
        // console.log(e)
        this.setState({
            nickNameVisible: false
        }, () => {
            this.changeNickName()
        })
    }

    //修改昵称
    changeNickName = () => {
        let params = {
            nick_name: this.state.nick_name
        }
        this.axios.post('/userEdit', params).then(res => {
            if (res.code === 10000){
                // window.location.reload()
                this.props.getusers()
            }
        })
    }
    render() {
        const {avatarVisible, phoneVisible, emailVisible, passwordVisible, nickNameVisible,  avatar, mobile, email, nick_name} = this.state
        return (
            <div className="user-box">
                <div className="setting">账号设置</div>
                <div className="setting-box">
                    <div className="avatar-border">
                        <div className="user-avatar">
                            {
                                avatar ? <img src={avatar} alt="" key={avatar}/> :  <img src={require('assets/images/user/user-set.png')} alt="" className="defaultimg"/>
                            }
                        </div>
                        <div className="changeimg" onClick={this.clickAvatar}>点击更换头像</div>
                    </div>
                    <div className="setcontent">
                        <div className={className([{'nickli-active': nickNameVisible}])}>
                            <span>昵称:</span>
                            {
                                nickNameVisible ? <Form  onFinish={this.handleFinish} onValuesChange={this.handleChange} onBlur={this.handleBlur}>
                                    <Form.Item
                                        className='item'
                                        name='nick_name'
                                        rules={[{ validator: this.nameChecked }]}
                                        validateTrigger='onChange'
                                        initialValue={nick_name}
                                    >
                                        <Input type="text" placeholder="请输入用户昵称" onPressEnter={this.pressEnter}/>
                                    </Form.Item>
                                </Form> : <div className="content" key={nick_name}>{nick_name}</div>
                            }
                            <img src={require('assets/images/user/bianxie.png')} alt="" className={className([{'bianxie-active': nickNameVisible}])} onClick={this.clickNickName}/>
                        </div>
                        <div>
                            <span>手机:</span>
                            <div className="content" key={mobile}>{mobile}</div>
                            <img src={require('assets/images/user/lianjie.png')} alt="" onClick={this.clickPhone}/>
                        </div>
                        <div>
                            <span>邮箱:</span>
                            <div className="content" key={email}>{email}</div>
                            <img src={require('assets/images/user/lianjie.png')} alt="" onClick={this.clickEmail}/>
                        </div>
                        <div>
                            <span>密码:</span>
                            <div className="content">******</div>
                            <img src={require('assets/images/user/mima.png')} alt="" onClick={this.clickPwd}/>
                        </div>
                    </div>
                </div>
                {mobile && <Avatar isVisible={avatarVisible} clickCancel={this.clickCancel}></Avatar>}
                {mobile && <Phone isVisible={phoneVisible} clickCancel={this.clickCancel} defaultMobile={mobile}></Phone>}
                {mobile && <Email isVisible={emailVisible} clickCancel={this.clickCancel}  defaultMobile={mobile}></Email>}
                {mobile && <Password isVisible={passwordVisible} clickCancel={this.clickCancel}  defaultMobile={mobile}></Password>}
            </div>
        )
    }
}

export default User

