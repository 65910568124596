import React from 'react'
import 'styles/components/avatar.scss'

interface stateType{
    imgError: boolean
}
interface propsType{
    photoUrl: string,
    photoWidth: string,
    photoHeight: string,
    isRadius: boolean
}
export default class Avatar extends React.Component<propsType, stateType>{
    constructor(props) {
        super(props)
        this.state = {
            imgError: false
        }
    
    }
    componentDidMount () {
    }

    ImgError = () => {
        this.setState({imgError: true})
    }
    /**
     * 必传参数:
     * photoUrl(图片路径)
     * photoWidth, photoHeight(背景图宽高)，例：'80px'，'80px'，
     * isRadius(是否为圆形)
     */
    render(){
        const {photoUrl, photoWidth, photoHeight, isRadius} = this.props
        return  photoUrl && !this.state.imgError ? <div className="custom-avatar"  style={{width: photoWidth, height: photoHeight,borderRadius:isRadius ? '50%' : 0, overflow: 'hidden'}}><img src={photoUrl} alt="" onError={this.ImgError} className="avatar"/></div> :
            <div  className="custom-no-avatar" style={{width: photoWidth, height: photoHeight,borderRadius:isRadius ? '50%' : 0, overflow: 'hidden'}}><img src={require('assets/images/no-avatar.png')} alt=""  className="no-avatar"/></div>
    }
}
