
import React, { Component } from 'react'
import { Skeleton } from 'antd'
import 'styles/components/subscribe.scss'
import { message, Input, Form, Modal, Button, Select } from 'antd'
import { connect } from 'react-redux'
import { addEmail, delEmail } from '../../redux/email/action'
import { getStorage } from 'utils/storage'
import className from 'classname'
interface stateType {
    subscription: boolean,
    isModalVisible: boolean,
    email: string,
    isSubscribe: boolean,
    subscribeLoad: boolean,
    emailData: any[],
    initVal: string,
    btnIsAble: boolean, 
    suggestVisible: boolean,
    isSelect: boolean
}
interface propsType {
    addEmail: any
}
@connect(state => state, { addEmail, delEmail })
class Subscribe extends Component<propsType, stateType> {
    // @ts-ignore
    formRef: FormInstance<any> = React.createRef()
    constructor(props: propsType) {
        super(props)
        this.state = {
            subscription: false,
            isModalVisible: false,
            email: '',
            isSubscribe: false,
            subscribeLoad: true,
            emailData: [],
            initVal: '',
            btnIsAble: false,
            suggestVisible: false,
            isSelect: false
        }

    }

    componentDidMount(){
        this.isSubscribe()
        this.getEmail()
    }

    //订阅成功
    subscription = () => {
        let token = getStorage('token')
        this.setState({
            btnIsAble: true,
        }, () => {
            if (!token){
                window.location.href = '/login'
            } else {
                // const regEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
                // const regEmail = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
                const regEmail = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,4}$/
                let email = this.state.email
                if (email && regEmail.test(email)) {
                    // @ts-ignore
                    this.axios.post('/subscribe',{email}).then(res => {
                        if (res.code === 10000){
                            // this.props.addEmail(this.state.email)
                            message.success({
                                content: <div className='toast'>
                                    <span>订阅成功！</span>
                                    <span>我们将定期通过邮件向您发送最新的科技资讯及活动</span>
                                </div>,
                                className: 'toast-box',
                                style: {
                                    marginTop: '5vh'
                                },
                            })
                            // this.setState({ subscription: true }, () => {
                            //     this.isSubscribe()
                            // })
                            setTimeout(() => {
                                this.setState({
                                    btnIsAble: true,
                                    subscription: true,
                                    initVal: ''

                                }, () => {
                                    this.isSubscribe()
                                })
                            },0)
                        }
                    })
                }
            }
        })
       
      
        
    }

    //取消订阅展示弹框
    cancel = () => {
        this.setState({ isModalVisible: true })
    }

    //确定取消订阅
    handleOk = () => {
        this.axios.post('/unSubscribe').then(res => {
            if (res.code === 10000){
                this.setState({ isModalVisible: false, subscription: false }, () => {
                    this.isSubscribe()
                })
                // @ts-ignore
                // this.props.delEmail()
                message.success({
                    content: <div className="toast">
                        <span>取消订阅成功！</span>
                    </div>,
                    className: 'toast-box',
                    style: {
                        marginTop: '5vh'
                    }
                })
            }
        })
       
    }

    //继续订阅
    handleCancel = () => {
        this.setState({ isModalVisible: false })
    }

    //input聚焦判断是否登录
    inpFocus = () => {
        let token = getStorage('token')
        if (!token){
            window.location.href = '/login'
        } else {
            this.setState({
                suggestVisible: true,
            })
            // this.getEmail()
        }
    }

    //输入框更改内容
    handleChange = (changedValues, allValues) => {
        // console.log('changedValues', changedValues)
        // console.log('allValues', allValues)
        this.setState({
            email: changedValues.email,
            isSelect: false,
            suggestVisible: false,
            // emailData: []
        })
    }

    
    //邮箱校验
    emailChecked = (rule, value) => {
        // const regEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
        const regEmail = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,4}$/
        if (!value) {
            return Promise.reject('请您输入邮箱地址完成订阅')
        } else {
            if (!regEmail.test(value)) {
                this.setState({btnIsAble: true})
                return Promise.reject('您输入的邮箱格式不正确 请重新输入')
            }
        }
        this.setState({btnIsAble: false})
        return Promise.resolve()
    }
    
    //初始判断是否订阅
    isSubscribe = () => {
        getStorage('token') && this.axios.post('/isSubscribe').then(res => {
            if (res.code === 10000){
                this.setState({
                    isSubscribe: res.data,
                    subscribeLoad: false
                })
            }
        })
    }

    //获取关联邮箱
    getEmail = () => {
        getStorage('token') && this.axios.get('/getEmail').then(res => {
            if (res.code === 10000){
                let emailData = []
                // console.log('this.getLocalEmail()', this.getLocalEmail())
                // emailData.push(res.data, ...this.getLocalEmail())
                res.data.email && emailData.push(res.data)
                // console.log('emailData', emailData)
                this.setState({
                    emailData
                }, () => {
                    // console.log(this.state.emailData)
                })
            }
        })
    }

    //获取本地存储邮箱
    getLocalEmail = () => {
        let localEamil = []
        for (var i = 0; i < localStorage.length; i++) {
            var key = localStorage.key(i) //获取本地存储的Key
            let val = localStorage.getItem(key)
            const regEmail = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,4}$/
            if (regEmail.test(val)){
                localEamil.push({email: val, local: true})
            }
        }
        return localEamil
    }
    
    //选中下拉提示邮箱
    checkEmail = email => {
        console.log('email', email)
        const regEmail = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,4}$/
        this.setState({
            initVal: email,
            email,
            isSelect: true,
            btnIsAble: regEmail.test(email) ? false : true
        }, () => {
            this.handleChange({email}, {})
        })
    }

    //input 失去焦点
    inpBlur = () => {
        this.setState({
            // emailData: [],
            // suggestVisible: false
        })
    }

    handleDouble = () => {
        return
    }
    render() {
        const { isModalVisible, isSubscribe, subscribeLoad, emailData, initVal, btnIsAble, suggestVisible, isSelect } = this.state
        // console.log('emailData', emailData)
        const { Option } = Select

        return (
            <div className="subscribe">
                <Skeleton active loading={false}>
                    {
                        isSubscribe ?
                            <>
                                <div className="intro">
                                    <img src={require('assets/images/yidingyue.png')} alt="" className="subscribeImg" />
                                    <div className="intro-title">
                                        <div className="main-title">您已成功订阅</div>
                                        <div>请留意邮件 了解新兴科技前沿资讯和活动</div>
                                    </div>
                                </div>
                                <div className="inpcont">
                                    <div className='cancel' onClick={() => this.cancel()}>取消订阅</div>
                                </div>
                            </>
                            :
                            <>
                                <div className="intro">
                                    <img src={require('assets/images/subscribe.svg')} alt="" className="subscribeImg" />
                                    <div className="intro-title">
                                        <div className="main-title">订阅NEWSLETTER</div>
                                        <div>随时了解新兴科技前沿资讯和活动</div>
                                    </div>
                                </div>
                                <div className="inpcont" >
                                    <Form name='email' ref={this.formRef} onValuesChange={this.handleChange} key={isSelect ? null : initVal} >
                                        <Form.Item name='email' rules={[ { validator: this.emailChecked }]} validateTrigger='onChange'  initialValue={isSelect ? null : initVal}>
                                            <Input className='inputbox' placeholder="输入您的电子邮箱，获取资讯" allowClear onFocus={this.inpFocus} onBlur={this.inpBlur} />
                                        </Form.Item>
                                        {
                                            emailData && emailData.length ? <div className={className("suggest-box",[{'suggest-active': suggestVisible}])}>
                                                {
                                                    emailData.map(item => {
                                                        return <div className="suggest-item" onClick={() =>this.checkEmail(item.email)}>
                                                            <span title={item.email}>{item.email}</span>
                                                            {
                                                                item.local ? null : <span>绑定邮箱</span>
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </div> : null
                                        }
                                        {/* {
                                            <Form.Item name='email'rules={[ { validator: this.emailChecked }]}  validateTrigger='onSearch' >
                                                <Select
                                                    mode="multiple"
                                                    allowClear
                                                    style={{ width: '100%' }}
                                                    placeholder="输入您的电子邮箱，获取资讯"
                                                    // defaultValue={['a10', 'c12']}
                                                    onSearch={this.changeSearch}
                                                >
                                                    {
                                                        emailData && emailData.map(item => {
                                                            return <Option className="suggest-item" onClick={() =>this.checkEmail(item.email)} value={item.email}>
                                                                <span title={item.email} className="emailtitle">{item.email}</span>
                                                                {
                                                                    item.local ? null : <span className="btn">绑定邮箱</span>
                                                                }
                                                            </Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>
                                          
                                        } */}
                                        <Button className={className(["desbutton",{'btnunable': btnIsAble}])} onClick={() => this.subscription()} disabled={btnIsAble} htmlType="submit" onDoubleClick={this.handleDouble}>订阅</Button>
                                    </Form>
                                </div>
                            </>
                    }
                </Skeleton>
                <Modal
                    title={<img src={require('assets/images/tishi.png')} alt="" />}
                    visible={isModalVisible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                    getContainer={false}
                    width={420}
                >
                    <span>是否确认取消订阅</span>
                    <span>取消订阅将无法接受到最新的新兴科技前沿资讯和活动</span>
                    <div className="btns">
                        <div onClick={()=>this.handleOk()}>确认取消</div>
                        <div onClick={()=>this.handleCancel()}>继续订阅</div>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default Subscribe

