
import React, { Component } from 'react'
import { Form } from 'antd'
import 'styles/login/login.scss'
import { setStorage } from 'utils/storage'
interface stateType{
    params:{
        username: string,
        password: string
    }
}
interface propsType{
}
class Login extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            params:{
                username: '',
                password: ''
            }
        }

    }
    
    componentDidMount(){
    }

    handleFinish = values => {
        console.log('allValues', values)
        let params = values
        this.axios.post('/login',params).then(res => {
            if (res.code === 10000){
                let token = res.data.token
                setStorage('token',token)
                window.location.href = '/'
            }
        })
    }

    handleChange = (changedValues, allValues) => {
        // console.log('changedValues', changedValues)
        let params = Object.assign({}, this.state.params, changedValues)
        this.setState({params}, () => {
            
        })
    }

    //用户名校验
    firstChecked = (rule, value) => {
        const regPhone = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
        // const regEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
        const regEmail = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,4}$/
        if (!value) {
            return Promise.reject('手机号或邮箱不能为空')
        } else {
            if (!regPhone.test(value) && !regEmail.test(value)) {
                return Promise.reject('手机号或邮箱格式不正确 请重新输入')
            }
        }
        return Promise.resolve()
    }

    //密码校验
    pwdChecked = (rule, value) => {
        let reg = /(?=.*[a-z])(?=.*\d)(?=.*[#@!~%^&*+=_])[a-z\d#@!~%^&*+=_]{6,20}/
        if (!value){
            return Promise.reject('密码不能为空')
        } else if (!reg.test(value)){
            return Promise.reject('您输入的密码不符合规则,请重新输入')
        }
        return Promise.resolve()
    }

    render() {
        return (
            <div className="login-box">
                <div className="title">登录</div>
                <div className="content">
                    <Form  onFinish={this.handleFinish} onValuesChange={this.handleChange}>
                        <Form.Item
                            className='item'
                            name='username'
                            rules={[{ validator: this.firstChecked }]}
                            validateTrigger='onChange'
                        >
                            <input type="text" placeholder="请输入手机号或电子邮箱" />
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='password'
                            rules={[{validator: this.pwdChecked}]}
                            validateTrigger='onChange'
                        >
                            <input type="password" placeholder="请输入密码" />
                        </Form.Item>
                        <div className="statusbox">
                            <a className="register" href='/regist'>去注册{'>'}</a>
                            <a className="forget" href='/forget'>忘记密码？</a>
                        </div>
                        <button type="submit" className="btnlogin"><span>登录</span><img src={require('assets/images/arrows.svg')} alt="" className="arrows"/></button>
                    </Form>
                    
                </div>
                
            </div>
        )
    }
}
export default Login

