
import React, { Component } from 'react'
import { Form, Modal, Button } from 'antd'
import 'styles/user/user.scss'
import { connect } from 'react-redux'
import { getusers } from '@redux/user/action'
interface stateType{
    codeBtnisAble: boolean,
    codeBtnisAble2: boolean,
    codeText: string,
    codeText2: string,
    maxTime: number,
    maxTime2: number,
    isTest: boolean,
    code: string,
    email: string,
    emailCode: string
}
interface propsType{
    isVisible: boolean,
    clickCancel: Function,
    defaultMobile: string,
    getusers: Function,
}
@connect(state => state, { getusers })
class Email extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            codeBtnisAble: false,
            codeBtnisAble2: false,
            codeText: '获取验证码',
            codeText2: '获取验证码',
            maxTime: 60,
            maxTime2: 60,
            isTest: false,
            code: '',
            email: '',
            emailCode: ''
        }
    }

    componentDidMount(){

    }
    //验证取消
    handleTestCancle = () =>{
        this.props.clickCancel()
    }

    //邮箱校验
    emailChecked = (rule, value) => {
        // const regEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
        const regEmail = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,4}$/
        if (!value) {
            return Promise.reject('邮箱不能为空')
        } else {
            if (!regEmail.test(value)) {
                return Promise.reject('邮箱格式不正确 请重新输入！')
            }
        }
        return Promise.resolve()
    }

    //确认验证
    handleTestFinish = values => {
        let params = {
            mobile: this.props.defaultMobile,
            code: this.state.code,
        }
        this.state.code && this.axios.post('/isCode', params).then(res => {
            if (res.code === 10000){
                this.handleTestCancle()
                this.setState({
                    isTest: true,
                    code: ''
                })
            }
        })
    }

    //验证input更改
    handleTestChange = (changedValues, allValues) => {
        // console.log('changedValues', changedValues)
        this.setState({
            code: changedValues.code
        })
    }

    //确定验证
    // handleTestOk = () => {
    //     this.handleTestCancle()
    //     let params = {
    //         mobile: this.props.defaultMobile,
    //         code: this.state.code,
    //     }
    //     this.state.code && this.axios.post('/isCode', params).then(res => {
    //         if (res.code === 10000){
    //             this.setState({
    //                 isTest: true,
    //                 code: ''
    //             })
    //         }
    //     })

    // }

    //绑定邮箱取消
    handleCancle = () =>{
        this.setState({
            isTest: false
        })
    }

    //绑定邮箱
    handleFinish = values => {
        console.log('allValues', values)
        this.bindEmail(values)
    }

    //绑定邮箱input更改
    handleChange = (changedValues, allValues) => {
        // console.log('changedValues', changedValues)
        let { email,code } = allValues
        this.setState({
            email, emailCode: code
        })

    }
    //获取验证码
    getCode = values => {
        let params = {
            mobile: this.props.defaultMobile,
            type: 2
        }
        this.axios.post('/code', params).then(res => {
            console.log(res)
            this.setState({ codeBtnisAble:true })
            let siv = setInterval(() => {
                this.setState( preState => ({
                    maxTime: preState.maxTime - 1,
                    codeText: `${preState.maxTime - 1}s`
                }), () => {
                    if (this.state.maxTime === 0) {
                        clearInterval(siv)
                        this.setState({
                            codeBtnisAble: false, codeText: "重新获取验证码", maxTime: 60
                        })
                    }
                })
            }, 1000)
        })
    }

    //获取邮件验证码
    getEmailCode = () => {
        let data = {
            email: this.state.email,
            type:2
        }
        // const regEmail = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/
        const regEmail = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,4}$/
        if (this.state.email && regEmail.test(this.state.email)){
            this.axios.post('/codeEmail',data).then(res => {
                console.log(res)
                this.setState({ codeBtnisAble2:true })
                let siv = setInterval(() => {
                    this.setState( preState => ({
                        maxTime2: preState.maxTime2 - 1,
                        codeText2: `${preState.maxTime2 - 1}s`
                    }), () => {
                        if (this.state.maxTime2 === 0) {
                            clearInterval(siv)
                            this.setState({
                                codeBtnisAble2: false, codeText2: "重新获取验证码", maxTime2: 60
                            })
                        }
                    })
                }, 1000)
            })
        }
    }

    //绑定邮箱
    bindEmail = values => {
        this.axios.post('/bindEmail', values).then(res => {
            if (res.code === 10000){
                // this.handleTestCancle()
                this.setState({
                    isTest: false
                }, () => {
                    this.props.getusers()
                })
            }
        })
    }

    render() {
        const {isVisible, defaultMobile} = this.props
        const {codeBtnisAble, codeText, isTest , codeBtnisAble2, codeText2,} = this.state
        return (
            <div>
                <Modal
                    className="email"
                    title="身份验证"
                    okText="验证"
                    visible={isVisible}
                    width={566}
                    onCancel={this.handleTestCancle}
                    // onOk={this.handleTestOk}
                    footer={null}
                    destroyOnClose
                >
                    <div className="content">
                        <div className="title">为了您的帐号安全，请先进行身份验证</div>
                        <Form  onFinish={this.handleTestFinish} onValuesChange={this.handleTestChange}>
                            <Form.Item
                                className='item'
                                name='mobile'
                                // rules={[{ validator: this.emailChecked }]}
                                // validateTrigger='onChange'
                            >
                                <input type="text" placeholder={`请使用手机号${defaultMobile}验证`} disabled/>
                            </Form.Item>
                            <Form.Item
                                className='item'
                                name='code'
                                rules={[{required:true, message:'短信验证码不能为空'}]}
                                validateTrigger='onChange'
                            >
                                <div>
                                    <input type="text" placeholder="请输入验证码" />
                                    <Button className='vercode' onClick={this.getCode} disabled={codeBtnisAble}>{codeText}</Button>
                                </div>
                            </Form.Item>
                            <button type="submit" className="yesok">验证</button>
                        </Form>
                    </div>
                </Modal>
                <Modal
                    className="email"
                    title="绑定邮箱"
                    okText="确定"
                    visible={isTest}
                    // visible={true}
                    width={566}
                    onCancel={this.handleCancle}
                    footer={null}
                    destroyOnClose
                >
                    <div className="content">
                        <Form  onFinish={this.handleFinish} onValuesChange={this.handleChange}>
                            <Form.Item
                                className='item'
                                name='email'
                                rules={[{ validator: this.emailChecked }]}
                                validateTrigger='onChange'
                            >
                                <input type="text" placeholder="请输入邮箱" />
                            </Form.Item>
                            <Form.Item
                                className='item'
                                name='code'
                                rules={[{required:true, message:'短信验证码不能为空'}]}
                                validateTrigger='onChange'
                            >
                                <div>
                                    <input type="text" placeholder="请输入验证码" />
                                    <Button className='vercode' onClick={this.getEmailCode} disabled={codeBtnisAble2}>{codeText2}</Button>
                                </div>
                            </Form.Item>
                            <button type="submit" className="yesok">确定</button>
                        </Form>
                    </div>
                </Modal>
            </div>
        )
    }
}
export default Email

