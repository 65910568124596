
import React, { Component } from 'react'
// import Swiper from 'swiper'
// import "swiper/swiper-bundle.css"
import Swiper from 'swiper'
import { Skeleton } from 'antd'
import 'swiper/css/swiper.css'
import 'styles/video/swiper.scss'
import SlideItem from 'comps/video/SlideItem'
interface stateType{
    videoData: any[],
    limit:number,
}
interface propsType{
}
class SwiperCom extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            videoData: [],
            limit:3,
        }

    }
    
    componentDidMount(){
        this.videoIntro()
        this.instanceSwiper()
    }

    componentDidUpdate() {
    }
    instanceSwiper = () => {
        new Swiper('.swiper-container', {
            slidesPerView: 1,
            loop: false,
            autoplay: {// 自动滑动
                delay: 3000, //3秒切换一次
                // stopOnLastSlide: false,
                disableOnInteraction: false,//
            },
            observer: true,//修改swiper自己或子元素时，自动初始化swiper    重要
            observeParents: true,//修改swiper的父元素时，自动初始化swiper  重要
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '">' + (index + 1) + '</span>'
                },
            },
        })
    }

    //推荐视频
    videoIntro = () => {
        this.axios.get('/movie/cover',{limit: this.state.limit}).then(res => {
            if (res.code === 10000){
                let videoData = res.data.slice(0,3)
                this.setState({
                    videoData,
                    // load: false
                })
            }
        })
    }

    render() {
        const {videoData} = this.state
        return (
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    {
                        videoData.map(item => {
                            return <SlideItem item={item}></SlideItem>
                        })
                    }
                </div>
                <div className="swiper-pagination"></div>
            </div>
        )
    }
}
export default SwiperCom

