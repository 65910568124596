
import React, { Component } from 'react'

class HomeIndex extends Component {
    componentDidMount(){
    }
    render() {
        return (
            <div>
            </div>
        )
    }
}
export default HomeIndex

