
import React, { Component } from 'react'
import 'styles/contract.scss'
interface stateType{
}
interface propsType{
}
class Contract extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            
        }

    }
    
    componentDidMount(){
    }
    render() {
        return (
            <div className="contract">
                <div className="title">用户注册协议</div>
                <div className="content">
                    <p>一、总则</p>
                    <p>1.1 《麻省理工科技评论》中文网的所有权和运营权归北京演绎科技有限公司所有。北京演绎科技有限公司为《麻省理工科技评论》（MIT Technology Review）中国唯一版权合作方。</p> 

                    <p>1.2 用户在注册之前，应当仔细阅读本协议，并同意遵守本协议后方可成为注册用户。一旦注册成功，则用户与《麻省理工科技评论》中文网之间自动形成协议关系，用户应当受本协议的约束。用户在使用特殊的服务或产品时，应当同意接受相关协议后方能使用。</p> 

                    <p>1.3 本协议则可由《麻省理工科技评论》中文网随时更新，用户应当及时关注并同意本站不承担通知义务。本站的通知、公告、声明或其它类似内容是本协议的一部分。</p>

                    <p>二、服务内容</p>

                    <p>2.1 《麻省理工科技评论》中文网的具体内容由本站根据实际情况提供。</p>

                    <p>2.2 本站仅提供相关的内容资讯，除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)均应由用户自行负担。</p>

                    <p> 三、用户帐号</p>

                    <p>3.1 经本站注册系统完成注册程序并通过身份认证的用户即成为正式用户，可以获得本站规定用户所应享有的一切权限；未经认证仅享有本站规定的部分会员权限。麻省理工科技评论有权对会员的权限设计进行变更。</p> 

                    <p>3.2 用户应按照注册要求使用真实的信息注册。用户有义务保证密码和帐号的安全，用户利用该密码和帐号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，本站不承担任何责任。如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管，如有必要，请通知本站。因黑客行为或用户的保管疏忽导致帐号非法使用，本站不承担任何责任。</p>
                    <p>四、使用规则</p>

                    <p>4.1 遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》和《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、实施办法。</p>  

                    <p>4.2 用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在本站任何页面发布、转载、传送含有下列内容之一的信息，否则本站有权自行处理并不通知用户：</p>

                    <p>(1)违反宪法确定的基本原则的；</p> 

                    <p>(2)危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的； </p>

                    <p>(3)损害国家荣誉和利益的；</p> 

                    <p>(4)煽动民族仇恨、民族歧视，破坏民族团结的；</p> 

                    <p>(5)破坏国家宗教政策，宣扬邪教和封建迷信的；</p> 

                    <p>(6)散布谣言，扰乱社会秩序，破坏社会稳定的；</p>

                    <p>(7)散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</p> 

                    <p>(8)侮辱或者诽谤他人，侵害他人合法权益的； </p>

                    <p>(9)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</p> 

                    <p>(10)以非法民间组织名义活动的；</p>

                    <p>(11)含有法律、行政法规禁止的其他内容的。</p>

                    <p>4.3 用户承诺对其发表或者上传于本站的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、音乐、电影、表演和录音录像制品和电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成本站被第三人索赔的，用户应全额补偿本站一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用)；</p> 

                    <p>4.4 当第三方认为用户发表或者上传于本站的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本站发送权利通知书时，用户同意本站可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，本站将不会自动恢复上述删除的信息； </p> 
                    <p>(1)不得为任何非法目的而使用网络服务系统；</p>
                    <p>(2)遵守所有与网络服务有关的网络协议、规定和程序；</p>
                    <p>(3)不得利用本站进行任何可能对互联网的正常运转造成不利影响的行为； </p>
                    <p>(4)不得利用本站进行任何不利于本站的行为。4.5 如用户在使用网络服务时违反上述任何规定，本站有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户发表的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。</p>

                    <p>五、隐私保护</p>

                    <p>5.1 本站不对外公开或向第三方提供单个用户的注册资料及用户在使用网络服务时存储在本站的非公开内容，但下列情况除外：</p>
                        
                    <p>(1)事先获得用户的明确授权；</p>

                    <p>(2)根据有关的法律法规要求；</p>

                    <p>(3)按照相关政府主管部门的要求；</p>

                    <p>(4)为维护社会公众的利益。</p>

                    <p>5.2 本站可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与本站同等的保护用户隐私的责任，则本站有权将用户的注册资料等提供给该第三方。</p>

                    <p>5.3 在不透露单个用户隐私资料的前提下，本站有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</p>
                </div>
                <a href='/regist' className="agreebtn">同意并继续</a>
            </div>
        )
    }
}
export default Contract

