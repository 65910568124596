
import React, { Component } from 'react'
import { Form, Modal, Button } from 'antd'
import Avatar from 'comps/Avatar'
import 'styles/user/user.scss'
import className from 'classname'
import { connect } from 'react-redux'
import { getusers } from '@redux/user/action'
import { removeStorage, getStorage } from 'utils/storage'
interface stateType{
    codeBtnisAble: boolean,
    codeText: string,
    pwdplaceholder: boolean,
    maxTime: number,
    params:{
        mobile: string,
        pwd: string,
        pwd2: string,
        code: string,
        email: string,
        nick_name: string
    },
    isCode: boolean
}
interface propsType{
    isVisible: boolean,
    clickCancel: Function,
    defaultMobile: string,
    getusers: Function
}
@connect(state => state, { getusers })
class Password extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            codeBtnisAble: false,
            codeText: '获取验证码',
            pwdplaceholder: true,
            maxTime: 60,
            params:{
                mobile: '',
                pwd: '',
                pwd2: '',
                code: '',
                email: '',
                nick_name: ''
            },
            isCode: false
        }
    }

    componentDidMount(){

    }

    //取消
    handleCancle = () =>{
        this.props.clickCancel()
    }

    //手机号校验
    firstChecked = (rule, value) => {
        const regPhone = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
        if (!value) {
            return Promise.reject('手机号不能为空')
        } else {
            if (!regPhone.test(value)) {
                return Promise.reject('手机号格式不正确 请重新输入')
            }
        }
        return Promise.resolve()
    }

    handleFinish = values => {
        console.log('allValues', values)
        let params = {
            pwd: values.pwd,
            pwd2: values.pwd2
        }
        this.state.isCode && this.axios.post('/rePwd', params).then(res => {
            if (res.code === 10000){
                this.handleCancle()
                removeStorage('token')
                window.location.href = '/login'
            }
        })
    }

    handleChange = (changedValues, allValues) => {
        // console.log('changedValues',  allValues)
        let params = Object.assign({}, this.state.params, allValues, {mobile: this.props.defaultMobile})
        this.setState({params})

    }

    //获取验证码
    getCode = values => {
        let params = {
            mobile: this.props.defaultMobile,
            type: 2
        }
        this.axios.post('/code', params).then(res => {
            console.log(res)
            this.setState({ codeBtnisAble:true })
            let siv = setInterval(() => {
                this.setState( preState => ({
                    maxTime: preState.maxTime - 1,
                    codeText: `${preState.maxTime - 1}s`
                }), () => {
                    if (this.state.maxTime === 0) {
                        clearInterval(siv)
                        this.setState({
                            codeBtnisAble: false, codeText: "重新获取验证码", maxTime: 60
                        })
                    }
                })
            }, 1000)
        })
    }

    //校验验证码是否正确
    handleTestCode = e => {
        let params = {
            mobile: this.props.defaultMobile,
            code: this.state.params.code,
        }
        this.state.params.code && this.axios.post('/isCode', params).then(res => {
            if (res.code === 10000){
                this.setState({
                    isCode: true
                })
            }
        })

    }

    //密码校验
    pwdChecked = (rule, value) => {
        // let reg = /^(?![A-z0-9]+$)(?![A-z~@*()_]+$)(?![0-9~@*()_]+$)([A-z0-9~@*()_]{6,20})$/
        let reg = /(?=.*[a-z])(?=.*\d)(?=.*[#@!~%^&*+=_])[a-z\d#@!~%^&*+=_]{6,20}/
        if (!value){
            return Promise.reject('密码不能为空')
        } else if (!reg.test(value) || value.length < 6 || value.length > 20){
            return Promise.reject('您输入的密码不符合规则,请重新输入')
        }
        return Promise.resolve()
    }

    //重置密码校验
    pwd2Checked = (rule, value) => {
        if (!value){
            return Promise.reject('密码不能为空')
        } else if (value !== this.state.params.pwd){
            return Promise.reject('两次输入的密码不一致，请重新输入')
        }
        return Promise.resolve()
    }

    //输入密码
    pwdInput = () => {
        this.setState({
            pwdplaceholder: false
        })
    }
    render() {
        const {isVisible, defaultMobile} = this.props
        const {codeBtnisAble, codeText, pwdplaceholder, params} = this.state
        return (
            <Modal
                className="password"
                title="修改密码"
                okText="确定"
                visible={isVisible}
                // visible={true}
                width={566}
                onCancel={this.handleCancle}
                footer={null}
                destroyOnClose
            >
                <div className="content">
                    <div className="title">为了您的帐号安全，请先进行身份验证</div>
                    <Form  onFinish={this.handleFinish} onValuesChange={this.handleChange}>
                        <Form.Item
                            className='item'
                            name='mobile'
                            // rules={[{ validator: this.firstChecked }]}
                            // validateTrigger='onChange'
                        >
                            <input type="text" placeholder={`请使用手机号${defaultMobile}验证`} disabled/>
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='code'
                            rules={[{required:true, message:'短信验证码不能为空'}]}
                            validateTrigger='onBlur'
                        >
                            <div>
                                <input type="text" placeholder="请输入验证码" onBlur={this.handleTestCode}/>
                                <Button className='vercode' onClick={this.getCode} disabled={codeBtnisAble}>{codeText}</Button>
                            </div>
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='pwd'
                            rules={[{validator: this.pwdChecked}]}
                            validateTrigger='onChange'
                        >
                            <div className="pwdcon">
                                <input type="password" placeholder="请输入密码"  onInput={this.pwdInput}/>
                                <span className={className([{'pwdplace' : !pwdplaceholder && params.pwd}])}>6-20位字母、数字、符号组合</span>
                            </div>
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='pwd2'
                            rules={[{ validator: this.pwd2Checked }]}
                            validateTrigger='onChange'
                        >
                            <input type="password" placeholder="请再次输入密码" />
                        </Form.Item>
                        <button type="submit" className="yesok">确定</button>
                    </Form>
                </div>
            </Modal>
        )
    }
}
export default Password

