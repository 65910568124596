import Axios from 'libs/axios'
import { removeStorage, getStorage } from 'utils/storage'
const getusers =  () => {
    return dispatch => {
        getStorage('token') && Axios.post('/getUser').then(res=>{
            if (res.code === 10000){
                // console.log('res.data',res.data)
                dispatch({
                    type: 'USERS',
                    payload: res.data
                })

            }
        })
    }
}

export {getusers}